.indiasquash-main-wrapper {
  // border: 1px solid green;
  // width: 100%;
  // height: 100%;

  .indiasquash_inner_wrapper {
    // border: 1px solid blue;
    background-image: url("../../../assets/Images/SRFIbannerimage-02.jpg");
    height: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;

    padding-top: 57px;

    .navbar2-container {
      border-radius: 5px;
      background: #f04a3e;
      height: 75px;
      width: 71%;
      padding-left: 10px;
      margin: 0 auto;
      // position: relative;
      // top: 35px;
      padding-right: 10px;

      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 1300px) {
        width: 85%;
      }
    }

    .indiasquash-inner {
      width: 71%;
      height: 100%;
      background-color: #f5f4f4;
      color: #000;
      padding: 5px 15px;
      margin-top: 20px;

      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 1300px) {
        width: 85%;
      }
      .rowss {
        .indiasquash-content {
          .heading {
            // border: 1px solid;
            display: flex;
            justify-content: start;
            // margin: 15px;
            margin-top: 20px;
            text-align: left;

            button {
              border: none;
              outline: none;
              background-color: transparent;
              color: #f04a3e;
              // font-family: Roboto;
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
          }

          .indiasquash {
            padding-left: 20px;
            // border: 1px soli

            // display: flex;
            // justify-self: left;

            @media screen and (min-width: 300px) and (max-width: 450px) {
              padding-left: 0px;
            }

            .state-dd {
              // border: 1px solid blue;
              display: flex;
              justify-content: left;
              align-items: center;
              // margin: 10px;

              .select-state {
                color: #000;
                // font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin: 15px;
              }

              select {
                width: 298px;
                height: 35px;
                border-radius: 5px;
                background: #fff;
                color: #000;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

                option {
                  // border: 1px solid red;
                  padding: 20px;
                  // color: red !important;
                }
                // @-moz-document url-prefix() {
                //   option  {

                //   color: blue !important;
                //   font-style: ;
                // }

                // }
              }
            }

            .dropdown {
              position: relative;
              display: inline-block;

              .selected {
                padding: 10px;
                background-color: #f0f0f0;
                // border: 1px solid #ccc;
                cursor: pointer;
              }

              /* Apply styles when selected item is focused */
              .selected:focus + .options {
                display: block;
              }

              .options {
                display: none;
                position: absolute;
                list-style: none;
                margin: 0;
                padding: 0;
                background-color: #fff;
                border: 1px solid #ccc;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              }

              .options li {
                padding: 10px;
                cursor: pointer;

                &:hover {
                  background-color: #f0f0f0;
                }
              }
            }

            .card {
              // border: 3px solid green;
              // padding: 10px;
              width: 80%;
              height: 100%;
              margin: 0 auto;
              border-radius: 5px;
              background: #fff;
              box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

              @media screen and (min-width: 300px) and (max-width: 450px) {
                width: 100%;
                margin: 0 auto;
                // border: 1px solid;
              }

              .row {
                .img-wrap {
                  // border: 1px solid;
                  // width: 280px;
                  // height: 275px;
                  border-radius: 5px;
                  margin: 0 auto;

                  img {
                    // width: 100% !important;
                    // height: 100%;
                    // border-radius: 5px;
                    // border: 1px solid #f0f0f0;
                    // margin: 0 auto;
                    // // margin-top: 10px;
                    // margin-bottom: 10px;

                    width: 75% !important;
                    height: 100%;
                    border-radius: 5px;
                    border: 1px solid #f0f0f0;
                    margin: 0 auto;

                    @media screen and (min-width: 300px) and (max-width: 855px) {
                      width: 100% !important;
                    }
                  }
                }

                .content-wrap {
                  .card-block {
                    // border: 1px solid green;
                    height: 100%;
                    padding-left: 15px;
                    padding-top: 25px;

                    .stateunitsname {
                      color: #000;
                      // font-family: Roboto;
                      font-size: 20px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      text-align: left;
                    }
                    .contactno {
                      color: #000;
                      // font-family: Roboto;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 2;
                      text-align: left;
                    }
                  }
                }
              }
            }

            .state-info-wrap {
              // border: 1px solid yellowgreen;
              margin-top: 20px;

              .state-info {
                color: red;
                display: flex;
                justify-content: left;
                padding-left: 20px;
                color: #000;
                // font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }

              .stateadrress-wrap {
                width: 80%;
                height: 100%;
                border-radius: 5px;
                background: #fff;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                padding: 15px;
                // margin: 20px;
                margin: 0 auto;
                margin-top: 20px;

                @media screen and (min-width: 300px) and (max-width: 450px) {
                 width: 100%;
                }

                span {
                  color: #000;
                  font-family: Roboto;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                }
              }
            }
          }

          .register-player-mainwrapper {
            margin-top: 35px;
            padding-left: 20px;

            .subnavbar {
              display: flex;
              gap: 20px;
              background-color: #f0f0f0;
              padding: 10px;

              .tab {
                margin-right: 10px;
                position: relative; /* Set the position to relative */
              }

              button {
                background-color: transparent;
                border: none;
                cursor: pointer;
                outline: none;
                font-size: 16px;
                color: #000;

                &.active {
                  font-weight: bold;
                  color: #000;
                  // font-family: Roboto;
                  font-size: 18px;
                  // font-style: normal;
                  font-weight: 600;
                  line-height: normal;

                  &::after {
                    content: "";
                    display: block;
                    width: 100%; /* Adjust the width as needed */
                    border-bottom: 3px solid #f04a3e; /* Adjust the thickness and color as needed */
                    position: absolute;
                    bottom: 0;
                    left: 0;
                  }
                }
              }

              button {
                background-color: transparent;
                border: none;
                cursor: pointer;
                outline: none;
                font-size: 16px;

                &.active {
                  font-weight: bold;
                }
              }
            }

            thead {
              background-color: #f04a3e;

              tr {
                th {
                  text-align: left;
                  color: #fff;
                  // font-family: Roboto;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                }
              }
            }

            tbody {
              tr {
                td {
                  color: #000;
                  // font-family: Roboto;
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  text-align: left;
                }
              }
            }

            .pagination {
              display: flex;
              padding-left: 0;
              list-style: none;
              border-radius: 0.25rem;
              float: right;
            }

            .page-item.active .page-link {
              z-index: 3;
              color: black;
              background-color: #007bff;
              border-color: #007bff;
              border-radius: 5px;
              background: #fff;
              box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset,
                2px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
            }

            a.page-link.page-link {
              border: none;
              color: black;
            }

            .page-item {
              // Common styles for all pagination items
              a.page-link {
                border: none;
                color: black;
                cursor: pointer;
              }
            }
            .page-item.active {
              // Styles for active pagination item
              a.page-link {
                color: #000;
                background-color: #007bff;
                border-color: #007bff;
                border-radius: 5px;
                background: #fff;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset,
                  2px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
                cursor: default; // Change the cursor for active item
              }
            }

            .page-item.disabled {
              // Styles for disabled pagination item
              a.page-link {
                color: #888;
                cursor: not-allowed; // Change the cursor for disabled item
              }
            }
          }
          // ::before.sorting-icon {
          //   content: '\e9c2' !important;
          //   margin-top: -10px !important;
          //   color: #999999 !important;
          //   font-family: 'icomoon' !important;
          // }

          // ::after.sorting-icon {
          //   content: '\e9c1' !important;
          //   margin-top: -10px !important;
          //   color: #999999 !important;
          //   font-family: 'icomoon' !important;
          // }
        }
      }
    }
  }

  .scroll-to-top {
    // display: none;
    position: fixed;
    bottom: 20px;
    right: 50px;
    // background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 99999;
    outline: none;

    svg {
      display: none;
    }

    @media screen and (min-width: 300px) and (max-width: 550px) {
      position: fixed !important;
      bottom: 30px !important;
      right: 30px !important;
      // border: 1px solid white !important;
    }

    // #scrollToTopButton {
    //   // display: none;
    //   position: fixed;
    //   bottom: 20px;
    //   right: 50px;
    //   // background-color: #007BFF;
    //   color: white;
    //   border: none;
    //   border-radius: 50%;
    //   padding: 10px 20px;
    //   cursor: pointer;
    //   z-index: 99999;
    //   outline: none;

    // @media screen and (min-width: 300px) and (max-width: 550px) {
    //   position: fixed;
    //   bottom: 20px;
    //   right: 10px;
    // }

    // .scrollup {
    //   height: 50px;
    //   width: 50px;
    //   border-radius: 50%;
    // }
    // }
  }
}
