.RANKING-PART1 {
  // border: 1px solid red; 
  padding-left:  0px !important;
    padding-right: 0px !important;
  .RANKING_inner_wrapper {
    // border: 1px solid blue;
    background-image: url("../../../assets/Images/SRFIbannerimage-02.jpg");
    height: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;

    padding-top: 57px;

    .navbar2-container-RANKING {
      border-radius: 5px;
      background: #f04a3e;
      height: 75px;
      width: 71%;
      // border:3px solid red;
      padding-left: 10px;
      margin: 0 auto;
      // position: relative;
      // top: 35px;
      padding-right: 10px;

      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 1300px) {
        width: 85%;
      }
    }

    .content-RANKING {
      width: 71%;
      height: 100%;
      background-color: #f5f4f4;
      color: #000;
      padding: 5px 15px !important;
    //  border:3px solid red ;
      margin: 0 auto !important;
      margin-top: 20px !important;
      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 1300px) {
        width: 85%;
      }
    }
  }
}













.rankingHeading {
  text-align: start;
  // border-bottom: 4px solid #f04a3e; // x
  margin-top: 4vh !important;
  // padding-bottom: 3vh;
}

// -------------------------drop-down------------
.fliters {
  // border:5px solid rgb(56, 56, 56) ;
  padding-left: 3vh;
  text-align: start;
  display: flex;
  gap: 2vh;
  align-items: center;
 justify-content: flex-start;
  border-radius: 5px;
}
  // -----------------media----------------
@media (max-width:"759px") {
  .fliters {
  flex-direction: column !important;
  margin-top: 2vh !important;
  justify-content: start !important;
  align-items: start !important;
  gap:2vh  !important;
  }
  .cardRANK{
    // border:5px solid rgb(12, 12, 12) ;
    width: 21vh !important;
  }
  .JUFORM{
    width: 100% !important;
  }
  .Filter{
    width: 100% !important;
  }
  .months-content{
    // bordeR:3px solid red !important;
    flex-direction: column !important;
    gap:2px !important;
    margin-top: -2.7vh;
  }
  .monthsVALIDATE{
    gap:2vh !important;
    // border:3px solid red !important;
    flex-direction: column !important;
  }
  .searchs {
    // border:4px solid red !important;
    justify-content: center !important;
  }
}
.cardRANK{
  // border:3px solid green;
  display: flex;
  flex-direction: row;
}
.dropmenusContent {
  width: 33vh;
  display: flex;
  // border:6px soli green !important;
  font-size: 19px;
  font-family: Roboto;
  font-weight: 400;
  color: #000000;
  outline: none;
  border:none;
  padding: 7px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
 @media screen  and (max-width:388px){
    width:22vh !important;
 }

 
}


.playersOption {
  font-size: 19px !important;
  font-family: Roboto !important;
  font-weight: 400 !important;
  color: #000000 !important;
 }

.playersOption:hover {
  background-color: #fff !important;
  color: red !important;
}

// -------------juniour--------
.JUFORM {
  display: inline;
  border: none;
  font-size: 19px;
  font-family: Roboto;
  font-weight: 400;
  color: #000000;
  outline: none;
  border:none;
  padding: 7px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px ;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.PROCOACH {
  border: 1px solid rgb(2, 2, 2);
  font-size: 18px;
  padding: 1vh;
  box-shadow: 0 0 2px #cecaca;
}


// ------------------------RankignMonths--heading---------
.months-content {
 
  // border:2px solid red ;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: row;
  gap: 3vh;

  @media (max-width:"759px") {
    gap: none !important;
    flex-direction: column !important;
  }
}
.JSMPART{
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: row;
  gap: 3vh;

  @media (max-width:"759px") {
    gap: none !important;
    flex-direction: column !important;
  }
}
.JSMPART1{
  display: flex;
  // border:2px solid red;
  // margin-top: 2.6vh;
  gap: 3vh;

  @media (max-width:"759px") {
    gap: 3vh;
  }
}

.RankingMonthsHEADING {
  // border:2px solid red;
  display: inline;
  text-align: start;
}

.monthsVALIDATE {
  display: flex;
  // border:2px solid red;
  margin-top: 2.6vh;
  gap: 3vh;

  @media (max-width:"759px") {
    gap: 3vh;
  }
}
.Filter {
  // border:2px solid rgb(0, 0, 0) !important;
  display: inline;
  border: none;
  font-size: 19px;
  font-family: Roboto;
  font-weight: 400;
  color: #000000;
  outline: none;
  border:none;
  padding: 7px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px ;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
// -----------------------Rankign-table-------------------
.Rankign-table{
  box-shadow:  0 0  3px #f7f3f3;
  // border:7px solid red !important ;
  //  margin-top: 6vh; 
}
.Table-Content{
  // border-radius: 3vh !important;
  // bordeR:7px solid black ; 
}
// -------table--------------------
.react-table{
//  border:9px solid green;  
 border-radius: 2vh !important;
}
.Headerpart{
  // background-color: #f04a3e;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  // border:7px solid red ;
  border-radius: 12vh !important;
  
}
.Headerpart .tRows{
  border-top-right-radius: 44vh !important;
  border-bottom: 2.3px solid #000 !important;
}
.tableDATA{
  font-size: 18px !important;
  font-family: Roboto;
}

// ----------------Table--------------
.custom-datatable .dataTables_length{
       display: none;
}
.custom-datatable .datatable_pagination .page-link{
  background-color: #000000 !important  ;
}


tbody{
  // color: red !important;
  text-transform: uppercase;
}



#scrollToTopButton {
  // display: none;
  position: fixed;
  bottom: 20px;
  right: 50px;
  // background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px 20px;
  cursor: pointer;
  z-index: 99999;
  outline: none;

  @media screen and (min-width: 300px) and (max-width: 550px) {
    position: fixed;
    bottom: 20px;
    right: 10px;

  }

  .scrollup {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
}