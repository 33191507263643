.socialmedia-mainwrapper {
  //   border: 1px solid green;
  background-image: url("../../assets//Images/SRFIbannerimage-02.jpg");
  height: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  width: 100%;
  min-height: 550px;
  max-height: 100%;

  @media screen and (min-width: 100px) and (max-width: 1200px) {
    height: auto;
  }

  .socialmedia-inner {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 100%;

    @media screen and (min-width: 100px) and (max-width: 1200px) {
      display: block;
    }

    .post-box {
      //   border: 1px solid red;
      width: 100%;
      //   height: 100%;
      // padding-left: 0px;
      margin-top: 80px;
      min-height: 450px;

      @media screen and (min-width: 100px) and (max-width: 650px) {
        max-width: 100%;
        margin: auto;
        height: 800px;
        padding-bottom: 50px;
        margin-bottom: 50px;
      }

      @media screen and (min-width: 651px) and (max-width: 1200px) {
        max-width: 100%;
        margin: auto;
        height: 0px;
      }

      .social-media-post-btn {
        // border: 1px solid blue;
        flex-direction: column;
        display: flex;
        // margin-left: 50px;
        // width: 60%;
        width: fit-content;

        @media screen and (min-width: 100px) and (max-width: 1200px) {
          padding-top: 30px;
        }

        @media screen and (min-width: 100px) and (max-width: 500px) {
          flex-direction: column;
          display: flex;
          align-items: center;
          padding-bottom: 0px;
          width: 100%;
        }

        button {
          border: none;
          outline: none;
          background-color: transparent;
          color: #000;
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          letter-spacing: 3.6px;
          text-align: left;
          cursor: default;

          @media screen and (min-width: 100px) and (max-width: 500px) {
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 3.6px;
            margin-top: 25px;
          }
        }

        .btn-border {
          height: 2.5px;
          // width: 200px;
          width: 100%;
          margin: 0 auto;

          @media screen and (min-width: 100px) and (max-width: 500px) {
            width: 265px;
          }
        }
      }
      // IMAGE DESIGN
      // .container-fluid {
      //   .row {
      //     margin-top: 40px;
      //     display: flex;
      //     justify-content: center;

      //     .social-img-outer {
      //       // border: 1px solid;
      //       // display: flex;
      //       // gap: 20px;
      //       // margin: 20px;

      //       // @media screen and (min-width: 100px) and (max-width: 768px) {
      //       //   display: block;
      //       // }

      //       .cont1 {
      //         // display: flex;
      //         // align-items: flex-start;
      //         // gap: 20px;

      //         height: 90%;
      //         width: 250px;
      //         box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
      //           0 6px 20px 0 rgba(0, 0, 0, 0.19);
      //         transition: all 0.2s ease-in-out;
      //         border-radius: 5%;
      //         margin: 0 auto;
      //         // cursor: pointer;

      //         .card-body {
      //           padding: 10px;

      //           img {
      //             height: 100%;
      //             width: 100%;
      //             margin: 0 auto;
      //             // margin-top: 20px;
      //           }
      //         }
      //       }
      //       .cont2 {
      //         display: flex;
      //         align-items: flex-start;
      //         gap: 20px;

      //         img {
      //           width: 250px;
      //           height: auto;
      //           margin: 5px;
      //         }
      //       }
      //     }
      //   }
      // }

      // IFRAME DESIGN
      .container-fluid {
        .row {
          margin-top: 40px;
          display: flex;
          justify-content: flex-start;
          // width: 100%;
          height: 100%;

          // border: 1px solid;

          .social-img-outer {
            // border: 1px solid;
            display: flex;
            gap: 10px;
            // justify-content: space-evenly;
            // height: 100%;

            @media screen and (min-width: 100px) and (max-width: 650px) {
              display: block;
            }

            .cont1 {
              height: 66%;
              width: 300px;
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                0 6px 20px 0 rgba(0, 0, 0, 0.19);
              transition: all 0.2s ease-in-out;
              border-radius: 5%;
              margin: 0 auto;

              @media screen and (min-width: 100px) and (max-width: 650px) {
                height: 30%;
                margin-bottom: 10px;
              }
              @media screen and (min-width: 650px) and (max-width: 700px) {
                height: 43%;
              }
              @media screen and (min-width: 700px) and (max-width: 750px) {
                height: 46%;
              }
              @media screen and (min-width: 750px) and (max-width: 800px) {
                height: 50%;
              }
              @media screen and (min-width: 800px) and (max-width: 850px) {
                height: 55%;
              }
              @media screen and (min-width: 850px) and (max-width: 900px) {
                height: 58%;
              }
              @media screen and (min-width: 900px) and (max-width: 950px) {
                height: 59%;
              }

              .card-body {
                padding: 10px;

                iframe {
                  height: 100%;
                  width: 100%;
                  margin: 0 auto;
                  // margin-top: 20px;
                }
              }
            }

            .cont2 {
              display: flex;
              align-items: flex-start;
              gap: 20px;

              img {
                width: 250px;
                height: auto;
                margin: 5px;
              }
            }
          }
        }
      }
    }

    .follow-box {
      //   border: 1px solid red;
      margin-top: 80px;

      @media screen and (min-width: 100px) and (max-width: 1200px) {
        max-width: 100%;
        margin: auto;
        margin-top: 30px;
        width: 100%;
      }

      .followus-btn {
        // border: 1px solid blue;
        flex-direction: column;
        display: flex;
        // margin-left: 50px;
        width: fit-content;

        @media screen and (min-width: 100px) and (max-width: 500px) {
          flex-direction: column;
          display: flex;
          align-items: center;
          padding-bottom: 0px;

          width: 100%;
        }

        button {
          border: none;
          outline: none;
          background-color: transparent;
          color: #000;
          font-size: 36px;
          cursor: default;
          font-style: normal;
          font-weight: 700;
          letter-spacing: 3.6px;
          text-align: left;
          @media screen and (min-width: 100px) and (max-width: 500px) {
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 3.6px;
            // margin-top: 25px;
          }
        }

        .btn-border {
          // width: 165px;
          width: 100%;
          height: 2.5px;
          margin: 0 auto;

          @media screen and (min-width: 100px) and (max-width: 500px) {
            width: 137px;
          }
        }
      }

      .followus-inner {
        // border: 1px solid green;
        margin-top: 20px;
        display: flex;
        justify-content: left;

        @media screen and (min-width: 100px) and (max-width: 1200px) {
          margin-top: 20px;
          display: flex;
          justify-content: center;
        }

        .face-insta {
          .facebook-btn {
            width: 248px;
            height: 52px;
            border: none;
            outline: none;
            border-radius: 5px;
            background: #000;

            margin: 20px;
            margin-left: 0px;
            @media screen and (min-width: 300px) and (max-width: 550px) {
              margin-left: 10px;
            }

            a {
              display: flex;
              justify-content: left;
              align-items: center;
              gap: 10px;
              padding-left: 25px;

              svg {
                color: #fff;
              }
              .facebook-img {
              }
              .facebook-name {
                color: #fff;
                // font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                // line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}
