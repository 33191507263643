.office-beares-main-wrapper {
  .indiasquash_inner_wrapper {
    // border: 1px solid blue;
    background-image: url("../../../assets/Images/SRFIbannerimage-02.jpg");
    height: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;

    padding-top: 57px;


    .navbar2-container {
      border-radius: 5px;
      background: #f04a3e;
      height: 75px;
      width: 71%;
      padding-left: 10px;
      margin: 0 auto;
      // position: relative;
      // top: 35px;
      padding-right: 10px;

      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 1300px) {
        width: 85%;
      }

      @media screen and (min-width: 1200px) and (max-width: 1600px) {
        width: 95%;
      }
    }

    .office-beares-inner {
      width: 71%;
      height: 100%;
      background-color: #f5f4f4;
      color: #000;
      padding: 5px 15px;
      margin: 0 auto;
      margin-top: 20px;
      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 1300px) {
        width: 85%;
      }

      @media screen and (min-width: 1200px) and (max-width: 1600px) {
        width: 95%;
      }
      .shimmer {
        margin-top: 20px;
        text-align: left;
        h4 {
          text-align: left;

          b {
            color: #f04a3e;
            // font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }

      .ggg {
        background-color: #f1f3fa;
        width: 100%;
        height: 100%;
        overflow: auto;

        .container-flu {
          // height: 200px;
          // width: 200px;
          width: 100%;
          height: 100%;
          // border: 1px solid;
          background-color: #f1f3fa;

          @media screen and (min-width: 300px) and (max-width: 450px) {
            width: 100%;
            padding: 0px;
          }

          .row {
            width: 100%;
            height: 100%;
            //   border: 1px solid blue;

            @media screen and (min-width: 300px) and (max-width: 450px) {
              margin: 0px;
            }

            //   .column {
            //     display: flex;
            //     width: 100%;
            //     height: 100%;
            //     // height: 300px;
            //     margin-top: 3%;
            //     border: 1px solid red;
            //     padding: 10px;

            //     .column-one {
            //     height: 100%;
            //     width: 100%;
            //     margin: 10px;
            .cardwrap {

              @media screen and (min-width: 300px) and (max-width: 1000px) {
                padding-bottom: 15px;
              }

            .card-one {
              height: 100%;
              width: 100%;
              border-radius: 0px;
              border: none;
              // border: 1px solid red;
              padding: 0px 0px 0px 0px;

              

              @media screen and (min-width: 768px) and (max-width: 990px) {
                width: 75%;
                margin: 0 auto;
              }
              @media screen and (min-width: 990px) and (max-width: 1100px) {
                width: 110%;
                // margin: 0 auto;
              }

              .blur-img-div {
                width: 100%;
                height: 100px;
                //   border: 1px solid greenyellow;
                border-radius: 5px 5px 0px 0px;
                background: #f04a3e;
              }

              .rounded-image {
                border-radius: 50%;
                height: 135px;
                width: 135px;
                // z-index: 999;
                // margin-left: 90px;
                // margin-top: -80px;
                //   border: 3px solid #fff;
                position: absolute;
                left: 50%;
                top: 10%;
                transform: translate(-50%);
              }

              .card-body {
                width: 100%;
                padding-top: 60px;
                // border-radius: 5px;
                background: #fff;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                padding-bottom: 0px;


                .bcaption {
                  padding-left: 14px;
                  padding-top: 17px;
                  text-transform: uppercase;

                  h5 {
                    color: #000;
                    //   font-family: Roboto;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    //   line-height: normal;
                  }

                  p {
                    color: #000;
                    //   font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    //   line-height: normal;
                  }

                  .president {
                    color: #000;
                    //   font-family: Roboto;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    //   line-height: normal;
                  }

                  // .officetag {
                  //   //   font-size: 12px;
                  //   //   line-height: 11px;
                  //   // border: 1px solid;
                  //   width: 100%;
                  //   .tournament-category {
                  //     display: flex;
                  //     align-items: center;
                  //     gap: 10px;
                  //     justify-content: start;

                  //     .category-img {
                  //       width: 20px;
                  //       height: 20px;
                  //       padding: 1px;
                  //     }

                  //     .card-subtitle2 {
                  //       color: #000;
                  //       // font-family: Roboto;
                  //       font-size: 12px;
                  //       font-style: normal;
                  //       font-weight: 400;
                  //       padding-left: 10px;
                  //       // line-height: normal;
                  //       text-align: left;

                  //       // border: 1px solid;
                  //       width: 95%;
                  //       padding-right: 5px;
                  //     }
                  //   }
                  // }
                }
              }

              .card-footer {
                border: none;
                /* padding: 0px 0px 0px 0px; */
                border-radius: 0px 0px 5px 5px;
                background: #fff;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                
              .officetag {
                //   font-size: 12px;
                //   line-height: 11px;
                // border: 1px solid;
                width: 100%;
                .tournament-category {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  justify-content: start;
                  margin-bottom: 10px;

                  .category-img {
                    width: 20px;
                    height: 20px;
                    padding: 1px;
                  }

                  .card-subtitle2 {
                    color: #000;
                    // font-family: Roboto;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    padding-left: 10px;
                    // line-height: normal;
                    text-align: left;
                    // border: 1px solid;
                    width: 95%;
                    padding-right: 5px;
                    margin-bottom: 0px !important;

                  }
                }
              }

            }
            }
          }

            .more-details {
              display: flex;
              justify-content: space-around;
              width: 100%;
              // border: 1px solid;
              margin-top: 40px;

              @media screen and (min-width: 300px) and (max-width: 568px) {
                display: block;
                // margin: 30px;
              }

              .additional-content {
                @media screen and (min-width: 300px) and (max-width: 568px) {
                  padding: 15px;
                }
              }

              .additional-content {
                h6 {
                  color: #000;
                  // font-family: Roboto;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  // line-height: normal;

                  ul {
                    li {
                      color: #000;
                      text-align: center;
                      // font-family: Roboto;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 400;
                      // line-height: normal;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  #scrollToTopButton {
    // display: none;
    position: fixed;
    bottom: 20px;
    right: 50px;
    // background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 99999;
    outline: none;

    @media screen and (min-width: 300px) and (max-width: 550px) {
      position: fixed;
      bottom: 20px;
      right: 10px;

    }

    .scrollup {
      height: 50px;
      width: 50px;
      border-radius: 50%;
    }
  }
}
