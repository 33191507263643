// #Official{
//     border: 1px solid red;
//     padding-left: 0px !important;
//     padding-right: 0px !important;
//     background-image: url("../../../assets/Images/SRFIbannerimage-02.jpg");
//     height: 100%;
//     background-position: bottom;
//     background-repeat: no-repeat;
//     background-size: 100% 100%;
//     width: 100%;
// }
// #Navbar2-Official{
//     background: #f04a3e;
//     height: 75px;
//     width: 73%;
//     padding-left: 10px;
//     margin: 0 auto;
//     padding-right: 10px;
//     text-align: start;
//     border-radius: 5px;


//     @media screen and (min-width: 300px) and (max-width: 600px) {
       
//         width: 100% !important;
        
//     }
// }

// ------Officials-------------------------------

// .Official-content{
//     color: #fff;
//     border-radius: 5px;
//     width: 73%;
//     // width: 65%;
//     margin-top: 20px;
//     margin: 0 auto;
//     //   border:2px solid red;
//     background-color: #f5f4f4;
//     @media screen and (min-width: 300px) and (max-width: 600px) {
//         //  border:12px solid rgb(243, 0, 0) !important;
//              width: 100% !important;
             
//     }
//     .tabparts {
//         // border: 4px solid blue;
//         background-color: #f5f4f4;
//         border-bottom-right-radius: 5px;
//         border-bottom-left-radius: 5px;
//         border-top-right-radius: 5px;
//         border-top-left-radius: 5px;
//         padding: 0 !important;
//     }

//     .tab-Contents {
//         // border: 4px solid blue;
//         display: flex;
//         flex-direction: column;
//         width: 100%;
//         text-align: start;
//         margin-top: 20px;
//     }

//     .ActiveContent {
//         border: 3px solid red;
//     }

   
// }
// // ---------------Headings-------------------
.HeadingOFF {
    color: #f04a3e;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 24px;
    line-height: 28.13px;
    // border:2px solid red;
 
    
}
.paragraphOFF {
    color: #000;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    line-height: 25px;
    padding-left: 2vh;
    padding-right: 2vh;
    // border:2px solid red;
    padding: 2vh;
    margin-top: -3vh;

}


.PATHWAY-PART1 {
    // border: 1px solid red; 
    padding-left:  0px !important;
    padding-right: 0px !important;
    .PATHWAY_inner_wrapper {
      // border: 1px solid blue;
      background-image: url("../../../assets/Images/SRFIbannerimage-02.jpg");
      height: 100%;
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
  
      padding-top: 57px;

      .navbar2-container-PATH {
        border-radius: 5px;
        background: #f04a3e;
        height: 75px;
        width: 71%;
        padding-left: 10px;
        margin: 0 auto;
        // position: relative;
        // top: 35px;
        padding-right: 10px;
  
        @media screen and (min-width: 300px) and (max-width: 600px) {
          width: 100%;
        }
  
        @media screen and (min-width: 600px) and (max-width: 1300px) {
          width: 85%;
        }
      }
  
      .content-PATHWAY1 {
        width: 71%;
        height: 100%;
        background-color: #f5f4f4;
        color: #000;
        padding: 5px 15px !important;
      //  border:3px solid red ;
        margin: 0 auto !important;
        margin-top: 20px !important;
        @media screen and (min-width: 300px) and (max-width: 600px) {
          width: 100%;
        }
  
        @media screen and (min-width: 600px) and (max-width: 1300px) {
          width: 85%;
        }
      }
    }
  }
  

  