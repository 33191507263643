.ranking-main-container {
  // border: 1px solid green;
  background-image: url("../../assets//Images/SRFIbannerimage-02.jpg");
  height: 1100px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 1100px;
  width: 100%;

  @media screen and (min-width: 100px) and (max-width: 868px) {
    height: auto;
  }

  .latest-result-head {
    flex-direction: column;
    display: flex;
    align-items: center;
    padding-top: 15px;

    .ranking-btn {
      border: none;
      outline: none;
      background-color: transparent;
      margin-top: 20px;
      color: #000;
      // font-family: Roboto;
      font-size: 36.577px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 3.658px;
      margin-bottom: 5px;
      cursor: default;
      @media screen and (min-width: 100px) and (max-width: 500px) {
          color: #000;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          letter-spacing: 4px;
          // margin-top: 40px;

        }
    }

    .btn-border {
      height: 3px;
      width: 180px;
      margin-right: 7px;

      @media screen and (min-width: 300px) and (max-width: 550px) {
        height: 3px;
        width: 110px;
        margin-right: 7px;
      }
    }
  }

  .ranking-inner-wrapper {
    // border: 1px solid red;

    .junior-btn {
      border: none;
      outline: none;
      background-color: transparent;
      margin-top: 5px;

      color: #000;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 2.8px;
      cursor: default;
      @media screen and (min-width: 100px) and (max-width: 500px) {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 3.6px;
        // margin-top: 40px;
      }
    }

    .cont-wrap {
      //   border: 1px solid red;

      .row {
        .boys {
          // width: 555px;
          // height: 452px;
          margin-top: 20px;

          .btn-wrap {
            margin: 0 auto;
            width: 75%;
            display: flex;
            justify-content: start;
            // border: 1px solid;
            margin-bottom: 10px;


            @media screen and (min-width: 300px) and (max-width: 768px) {
              width: 100%;

            }

            @media screen and (min-width: 668px) and (max-width: 768px) {
              width: 75%;
              margin: 0 auto;
            }

            @media screen and (min-width: 768px) and (max-width: 1250px) {
              width: 100%;

            }
            

            .boys-btn {
              border: none;
              outline: none;
              background-color: transparent;
              width: 225px;
              height: 44px;
              border-left: 5px solid #3a8cd5;
              border-radius: 5.08px 5.08px 0px 0px;
              background: #f04a3e;

              color: #fff;
              font-size: 24.384px;
              font-style: normal;
              font-weight: 700;
              text-align: left;
              padding: 5px;
              // margin: 0 auto;
              // margin-right: 333px;

              @media screen and (min-width: 100px) and (max-width: 500px) {
                font-size: 20px;
                width: 150px;
                height: 40px;
              }
            }
          }

          .boys-container {
            width: 75%;
            height: 535px;
            border-radius: 5px 5px 5px 5px;
            border: 1px solid rgba(0, 0, 0, 0.3);
            // opacity: 0.5;
            background: transparent;
            box-shadow: 0px 4.064056873321533px 4.064056873321533px 0px
              rgba(0, 0, 0, 0.25);

            padding: 10px;
            // border: 1px solid blue;
            margin: 0 auto;

            @media screen and (min-width: 300px) and (max-width: 400px) {
              width: 100%;
            }

            @media screen and (min-width: 400px) and (max-width: 668px) {
              width: 100%;
            }

            @media screen and (min-width: 768px) and (max-width: 1250px) {
              width: 100%;
            }

            .card-cont {
              // width: 450px;
              height: 75px;
              margin: 0 auto;
              margin-top: 10px;
              background-color: transparent;
              border-radius: 5.08px;
              border: 1.016px solid rgba(0, 0, 0, 0.1);
              background: transparent;
              box-shadow: 0px 4.064056873321533px 4.064056873321533px 0px
                rgba(0, 0, 0, 0.25);
              width: 90%;
              margin-top: 25px;
              display: flex;

              @media screen and (min-width: 300px) and (max-width: 450px) { 
                margin-top: 30px;

              }

              @media screen and (min-width: 300px) and (max-width: 400px) {
                width: 100%;
              }

              @media screen and (min-width: 400px) and (max-width: 668px) {
                width: 100%;
              }

              @media screen and (min-width: 768px) and (max-width: 1250px) {
                width: 100%;
              }

              .profile-details {
                // border: 1px solid green;
                display: flex;
                // justify-content: space-around;
                gap: 5px;
                width: 50%;

                .card-img-top {
                  width: 80px;
                  height: 80px;
                  border-radius: 50%;
                  box-shadow: 0px 4.06406px 4.06406px 0px rgba(0, 0, 0, 0.25);
                  // background-color: #000;
                  margin-top: -16px;
                  border: 1px solid rgba(0, 0, 0, 0.5);
                  float: left;
                  margin-left: 10px;

                  @media screen and (min-width: 300px) and (max-width: 450px) {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    box-shadow: 0px 4.06406px 4.06406px 0px rgba(0, 0, 0, 0.25);
                    margin-top: -25px;
                    border: 1px solid rgba(0, 0, 0, 0.5);
                    float: left;
                    margin-left: 5px;
                  }
                }

                .name-venue {
                  width: 100%;
                  height: 100%;
                  //   border: 1px solid red;
                  padding-top: 20px;
                  padding-left: 5px;

                  @media screen and (min-width: 300px) and (max-width: 450px) {
                    padding-left: 0px
                  }



                  .player-name {
                    color: #000;
                    font-size: 16.256px;
                    font-style: normal;
                    font-weight: 500;
                    //   line-height: normal;
                    display: flex;
                    align-items: center;
                    padding-left: 5px;
                    width: 200px;

                    @media screen and (min-width: 300px) and (max-width: 450px) {
                      font-size: 14px;
                    }
                  }

                  .player-location {
                    color: #000;
                    font-size: 14.224px;
                    font-style: normal;
                    font-weight: 400;
                    //   line-height: normal;
                    display: flex;
                    align-items: center;
                    padding-left: 5px;

                    @media screen and (min-width: 300px) and (max-width: 450px) {
                      font-size: 13px;
                    }
                  }
                }
              }

              .stats-details {
                display: flex;
                gap: 5px;
                // border: 1px solid red;
                width: 50%;
                padding-left: 10px;
                padding-top: 20px;
                justify-content: end;
                gap: 25px;

                @media screen and (min-width: 300px) and (max-width: 450px) {
                  gap: 0px;
                  //  border: 1px solid;
                 }

                @media screen and (min-width: 450px) and (max-width: 668px) {
                  gap: 5px;
                }

                @media screen and (min-width: 768px) and (max-width: 1250px) {
                  gap: 5px;
                }

                .cat-poit {
                    // border: 1px solid red;
                    width: 60px;

                    @media screen and (min-width: 300px) and (max-width: 450px) {
                      width: 40px;
                      margin-right: 5px;

                    }

                  .category {
                    display: flex;
                    align-items: center;
                    color: #f04a3e;
                    font-size: 16.256px;
                    font-style: normal;
                    font-weight: 500;

                    @media screen and (min-width: 300px) and (max-width: 450px) {
                      font-size: 14px;
                    }
                  }

                  .points {
                    display: flex;
                    align-items: center;
                    color: #000;
                    font-size: 14.224px;
                    font-style: normal;
                    font-weight: 400;

                    @media screen and (min-width: 300px) and (max-width: 450px) {
                      font-size: 13px;
                    }
                  }
                }

                .rank-cont {
                  border: none;
                  outline: none;
                  background-color: transparent;
                  //   border: 1px solid;
                  position: relative;
                  bottom: 30px;
                  margin-right: 15px;
                  padding-bottom: 20px;
                  
                  @media screen and (min-width: 300px) and (max-width: 450px) {
                    margin-right: 0px;
                   }                

                  .rank {
                    display: block;
                    border-radius: 50%;
                    padding: 6px;
                    width: 33px;
                    height: 32px;
                    background-color: #fff;
                    box-shadow: 0px 2.0320284366607666px 4.064056873321533px 0px
                      rgba(0, 0, 0, 0.25) inset;
                    filter: drop-shadow(
                      0px 2.0320284366607666px 4.064056873321533px
                        rgba(0, 0, 0, 0.25)
                    );
                  }
                }
              }
            }
          
          }
          
          
        }

        .girls {
          // width: 555px;
          /* height: 452px; */
          margin-top: 20px;
          // border: 1px solid;

          .btn-wrap {
            margin: 0 auto;
            width: 75%;
            display: flex;
            justify-content: start;
            // border: 1px solid;
            margin-bottom: 10px;


            @media screen and (min-width: 300px) and (max-width: 768px) {
              width: 100%;

            }
            @media screen and (min-width: 668px) and (max-width: 768px) {
              width: 75%;
              margin: 0 auto;
            }

            @media screen and (min-width: 768px) and (max-width: 1250px) {
              width: 100%;

            }

            .girls-btn {
              border: none;
              outline: none;
              background-color: transparent;
              width: 225px;
              height: 44px;
              border-left: 5px solid #3a8cd5;
              border-radius: 5.08px 5.08px 0px 0px;
              background: #f04a3e;
              color: #fff;
              font-size: 24.384px;
              font-style: normal;
              font-weight: 700;
              text-align: left;
              padding: 5px;
              // display: flex;
              // margin-right: 333px;
              @media screen and (min-width: 100px) and (max-width: 500px) {
                font-size: 20px;
                width: 150px;
                height: 40px;
              }
            }
          }

          .girls-container {
            width: 75%;
            height: 535px;
            border-radius: 5px 5px 5px 5px;
            border: 1px solid rgba(0, 0, 0, 0.3);
            // opacity: 0.5;
            background: transparent;
            box-shadow: 0px 4.064056873321533px 4.064056873321533px 0px
              rgba(0, 0, 0, 0.25);

            padding: 10px;
            margin: 0 auto;


            @media screen and (min-width: 300px) and (max-width: 400px) {
              width: 100%;
            }

            @media screen and (min-width: 400px) and (max-width: 668px) {
              width: 100%;
            }

            @media screen and (min-width: 768px) and (max-width: 1250px) {
              width: 100%;
            }

            .card-cont {
              // width: 450px;
              height: 75px;
              margin: 0 auto;
              margin-top: 10px;
              background-color: transparent;
              border-radius: 5.08px;
              border: 1.016px solid rgba(0, 0, 0, 0.1);
              background: transparent;
              box-shadow: 0px 4.064056873321533px 4.064056873321533px 0px
                rgba(0, 0, 0, 0.25);
              width: 90%;
              margin-top: 25px;
              display: flex;

              @media screen and (min-width: 300px) and (max-width: 450px) { 
                margin-top: 30px;

              }


              @media screen and (min-width: 300px) and (max-width: 400px) {
                width: 100%;
              }


              @media screen and (min-width: 400px) and (max-width: 668px) {
                width: 100%;
              }
              
              @media screen and (min-width: 768px) and (max-width: 1250px) {
                width: 100%;
              }

              .profile-details {
                // border: 1px solid green;
                display: flex;
                // justify-content: space-around;
                gap: 5px;
                width: 50%;
                

                .card-img-top {
                  width: 80px;
                  height: 80px;
                  border-radius: 50%;
                  box-shadow: 0px 4.06406px 4.06406px 0px rgba(0, 0, 0, 0.25);
                  // background-color: #000;
                  margin-top: -16px;
                  border: 1px solid rgba(0, 0, 0, 0.5);
                  float: left;
                  margin-left: 10px;

                  @media screen and (min-width: 300px) and (max-width: 450px) {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    box-shadow: 0px 4.06406px 4.06406px 0px rgba(0, 0, 0, 0.25);
                    margin-top: -25px;
                    border: 1px solid rgba(0, 0, 0, 0.5);
                    float: left;
                    margin-left: 5px;
                  }
                }

                .name-venue {
                  width: 100%;
                  height: 100%;
                  //   border: 1px solid red;
                  padding-top: 20px;
                  padding-left: 5px;
                  @media screen and (min-width: 300px) and (max-width: 450px) {
                    padding-left: 0px
                  }


                  .player-name {
                    color: #000;
                    font-size: 16.256px;
                    font-style: normal;
                    font-weight: 500;
                    //   line-height: normal;
                    display: flex;
                    align-items: center;
                    padding-left: 5px;
                    width: 200px;

                    @media screen and (min-width: 300px) and (max-width: 450px) {
                      font-size: 14px;
                    }
                  }

                  .player-location {
                    color: #000;
                    font-size: 14.224px;
                    font-style: normal;
                    font-weight: 400;
                    //   line-height: normal;
                    display: flex;
                    align-items: center;
                    padding-left: 5px;

                    @media screen and (min-width: 300px) and (max-width: 450px) {
                      font-size: 13px;
                    }
                  }
                }
              }

              .stats-details {
                display: flex;
                gap: 5px;
                // border: 1px solid red;
                width: 50%;
                padding-left: 10px;
                padding-top: 20px;
                justify-content: end;
                gap: 25px;

                @media screen and (min-width: 300px) and (max-width: 450px) {
                  gap: 0px;
                  //  border: 1px solid;
                 }

                @media screen and (min-width: 450px) and (max-width: 668px) {
                  gap: 5px;
                }

                @media screen and (min-width: 768px) and (max-width: 1250px) {
                  gap: 5px;
                }

                .cat-poit {
                 // border: 1px solid red;
                 width: 60px;

                 @media screen and (min-width: 300px) and (max-width: 450px) {
                  width: 40px;
                  margin-right: 5px;

                }

                  .category {
                    display: flex;
                    align-items: center;
                    color: #f04a3e;
                    font-size: 16.256px;
                    font-style: normal;
                    font-weight: 500;

                    @media screen and (min-width: 300px) and (max-width: 450px) {
                      font-size: 14px;
                    }
                  }

                  .points {
                    display: flex;
                    align-items: center;
                    color: #000;
                    font-size: 14.224px;
                    font-style: normal;
                    font-weight: 400;

                    @media screen and (min-width: 300px) and (max-width: 450px) {
                      font-size: 13px;
                    }
                  }

                  @media screen and (min-width: 300px) and (max-width: 450px) {
                    width: 40px;
                   }

                }

                .rank-cont {
                  border: none;
                  outline: none;
                  background-color: transparent;
                  //   border: 1px solid;
                  position: relative;
                  bottom: 30px;
                  margin-right: 15px;
                  padding-bottom: 20px;

                  @media screen and (min-width: 300px) and (max-width: 450px) {
                    margin-right: 0px;
                   }

                  .rank {
                    display: block;
                    border-radius: 50%;
                    padding: 6px;
                    width: 33px;
                    height: 32px;
                    background-color: #fff;
                    box-shadow: 0px 2.0320284366607666px 4.064056873321533px 0px
                      rgba(0, 0, 0, 0.25) inset;
                    filter: drop-shadow(
                      0px 2.0320284366607666px 4.064056873321533px
                        rgba(0, 0, 0, 0.25)
                    );
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
