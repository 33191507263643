.hompage_main_wrapper {
  .homepage_inner_wrap {

    // .scroll-to-top {
    //   position: fixed;
    //   bottom: 50px;
    //   right: 50px;

    //   width: 50px;
    //   height: 50px;

    //   color: black;
    //   background-color: #33dfd6;

    //   border: none;
    //   border-radius: 50px;

    //   cursor:crosshair;
    // }

    // .content-container {
    //   width: 960px;
    //   height: 2000px;

    //   margin: 30px auto;

    //   p {
    //     margin-bottom: 50px;
    //   }
    // }

        #scrollToTopButton {
            // display: none;
            position: fixed;
            bottom: 20px;
            right: 50px;
            // background-color: #007BFF;
            color: white;
            border: none;
            border-radius: 50%;
            padding: 10px 20px;
            cursor: pointer;
            z-index: 99999;
            outline: none;

            @media screen and (min-width: 300px) and (max-width: 550px) {
              position: fixed;
              bottom: 20px;
              right: 10px;

            }

            .scrollup {
              height: 50px;
              width: 50px;
              border-radius: 50%;
            }
          }
  }
}
