.news-main-wrapper {
  .indiasquash_inner_wrapper {
    // border: 1px solid blue;
    background-image: url("../../../assets/Images/SRFIbannerimage-02.jpg");
    height: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;

    padding-top: 57px;

    .navbar2-container {
      border-radius: 5px;
      background: #f04a3e;
      height: 75px;
      width: 71%;
      padding-left: 10px;
      margin: 0 auto;
      // position: relative;
      // top: 35px;
      padding-right: 10px;

      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 700px) {
        width: 100%;
      }

      @media screen and (min-width: 700px) and (max-width: 1300px) {
        width: 85%;
      }
    }

    .news-inner {
      width: 71%;
      height: 100%;
      background-color: #f5f4f4;
      color: #000;
      padding: 5px 15px;
      margin: 0 auto;
      margin-top: 20px;
      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 700px) {
        width: 100%;
      }

      @media screen and (min-width: 700px) and (max-width: 1300px) {
        width: 85%;
      }

      .shimmer {
        padding: 15px;
        padding-left: 0px;

        h4 {
          text-align: left;

          b {
            color: #f04a3e;
            // font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }
    }

    .news-documnets-container {
      .inner-tabs {
        .subnavbar {
          display: flex;
          gap: 20px;
          background-color: #f0f0f0;
          padding: 10px;

          @media screen and (min-width: 200px) and (max-width: 400px) {
            gap: 8px;
          }

          .tab {
            margin-right: 10px;
            position: relative; /* Set the position to relative */
          }

          button {
            background-color: transparent;
            border: none;
            cursor: pointer;
            outline: none;
            font-size: 16px;
            color: #000;

            &.active {
              font-weight: bold;

              &::after {
                content: "";
                display: block;
                width: 100%; /* Adjust the width as needed */
                border-bottom: 3px solid #f04a3e; /* Adjust the thickness and color as needed */
                position: absolute;
                bottom: 0;
                left: 0;
              }
            }
          }

          button {
            background-color: transparent;
            border: none;
            cursor: pointer;
            outline: none;
            font-size: 16px;

            &.active {
              font-weight: bold;
            }
          }
        }

        .tab-content {
          margin-top: 10px;

          .govt-wrap {
            // margin: 0 auto;
            width: 100%;
            // border: 1px solid;

            .news-cards {
              //   max-width: 300px;

              .container-fluid {
                .newsputs-immer-wrapper {
                  width: 100%;
                  // border: 1px solid;

                  .back-btn {
                    display: flex;
                    svg {
                      height: 15px !important;
                      width: 15px !important;
                      margin: 4px;
                    }
                    padding: 8px;
                    color: #fff;
                    border-radius: 5px;
                    background: #f04a3e;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                  }

                  .newsputs-main {
                    padding: 20px;

                    .newsputs-inner {
                      .container-fluid {
                        .custom-row {
                          display: flex;
                          flex-wrap: wrap;

                          .contents1 {
                            // flex: 0 0 calc(30% - 10px); /* Each column takes up 50% of the row's width with spacing */
                            // box-sizing: border-box;
                            // padding: 10px;
                            // display: flex;
                            // flex-direction: column;
                            // margin: 0 auto;

                            padding: 10px;
                            // display: flex;
                            margin: 0 auto;
                            // border: 1px solid;
                            // height: 350px;
                            // width: 350px;

                            .news-title {
                              // color: #f04a3e;
                              font-weight: 600;
                              font-size: 20px;
                              padding: 10px;
                            }
                            .news-img {
                              // max-width: 100% !important;
                              width: 80% !important;
                              // height: 100% !important;
                            }
                          }
                        }

                        .contents {
                          flex-grow: 1 !important; /* Allow the content to grow to fill available space */
                          overflow-y: auto !important; /* Add a scrollbar when content overflows the container */
                          padding-left: 20px; /* Add some spacing between the image and content */
                          margin-top: 20px; /* Add some space between the title and content */

                          .news-desc {
                            color: #000;
                            font-family: Roboto !important;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            text-align: left;

                            p span {
                              color: #000;
                              font-family: Roboto !important;
                              font-size: 14px !important;
                              font-style: normal;
                              font-weight: 400;
                              text-align: left !important;
                            }

                            table {
                              // border: 1px solid red !important;
                              margin: 0 auto !important;
                              width: 1200px !important;

                              @media screen and (min-width: 300px) and (max-width: 900px) {
                                width: 100% !important;
                              }

                              // img {
                              //   height: 200px !important;
                              //   width: 200px !important;
                              // }
                              td {
                                padding: 5px !important;
                              }
                            }
                            .CToWUd {
                              // border: 1px solid red !important;
                              margin: 0 auto !important;
                              // display: flex !important;
                            }

                            .CToWUd.a6T {
                              // border: 1px solid red !important;
                              margin: 0 auto !important;
                              display: flex !important;
                            }
                          }
                        }
                      }
                    }
                  }
                }

                .row {
                  .card-outer {
                    .card {
                      height: 90%;
                      width: 250px;
                      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                        0 6px 20px 0 rgba(0, 0, 0, 0.19);
                      transition: all 0.2s ease-in-out;
                      border-radius: 5%;
                      margin: 0 auto;
                      cursor: pointer;

                      .pdf-image {
                        height: 150px;
                        width: 150px;
                        margin: 0 auto;
                        margin-top: 20px;
                      }

                      .card-body {
                        padding: 27px;
                        cursor: pointer !important;

                        .card-text {
                          color: black;
                          // margin-bottom: 1px;
                          margin-top: -14px;
                          font-size: 16px;
                          text-align: center;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  #scrollToTopButton {
    // display: none;
    position: fixed;
    bottom: 20px;
    right: 50px;
    // background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 99999;
    outline: none;

    @media screen and (min-width: 300px) and (max-width: 550px) {
      position: fixed;
      bottom: 20px;
      right: 10px;

    }
    .scrollup {
      height: 50px;
      width: 50px;
      border-radius: 50%;
    }
  }
}
