.privacy-policy-main-wrapper {
  .indiasquash_inner_wrapper {
    // border: 1px solid blue;
    background-image: url("../../assets/Images/SRFIbannerimage-02.jpg");
    height: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;

    padding-top: 57px;

    .navbar2-container {
      border-radius: 5px;
      background: #f04a3e;
      height: 75px;
      width: 71%;
      padding-left: 10px;
      margin: 0 auto;
      // position: relative;
      // top: 35px;
      padding-right: 10px;

      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 1300px) {
        width: 85%;
      }
    }

    .policy-inner {
      width: 71%;
      height: 100%;
      background-color: #f5f4f4;
      color: #000;
      padding: 5px 15px;
      margin: 0 auto;
      margin-top: 20px;

      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 1300px) {
        width: 85%;
      }
      .shimmer {
        padding: 15px;
        padding-left: 0px;

        h4 {
          text-align: left;

          b {
            color: #f04a3e;
            // font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }

      .fontsizecontent {
        text-align: justify;
        padding-left: 15px;
      }

      .subhead {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: left;
        padding-left: 15px;
      }

      .subhead-main {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: left;
        padding-left: 15px;
      }

      .small-subhead {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: left;
        padding-left: 15px;
      }

      .contantli {
        text-align: left;
        list-style: disc;
        padding-left: 25px;

        // .contantul::before {
        //   content: "\2022";
        //   color: #ff0000;
        //   font-weight: bold;
        //   display: inline-block;
        //   width: 1em;
        //   margin-left: -1em;
        // }
      }
    }
  }
}
