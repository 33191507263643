.videos-main-wrapper {


  .indiasquash_inner_wrapper {
    // border: 1px solid blue;
    background-image: url("../../../assets/Images/SRFIbannerimage-02.jpg");
    height: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    // padding-top: 65px;

    padding-top: 57px;

    .navbar2-container {
      border-radius: 5px;
      background: #f04a3e;
      height: 75px;
      width: 71%;
      padding-left: 10px;
      margin: 0 auto;
      // position: relative;
      // top: 35px;
      padding-right: 10px;

      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 1300px) {
        width: 85%;

      }

    }

    .videos-inner {
      width: 71%;
      height: 100%;
      background-color: #f5f4f4;
      color: #000;
      padding: 5px 15px;
      margin: 0 auto;
      margin-top: 20px;
      border-top-right-radius: 5px !important;
      border-top-left-radius: 5px !important;

      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 1300px) {
        width: 85%;
      }

      .shimmer {
        h4 {
          text-align: left;

          b {
            color: #f04a3e;
            // font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }
    }

  }

}

.videopart {
  // border: 3px solid red;
  grid-template-columns: 3fr 3fr 3fr;
  padding: 3vh;
  gap: 8vh;
  padding-top: 5vh;
  display: grid;
  justify-content: center !important;
}
.videopart_CONTENT{
  // border:5px solid green  !important;
 height: 120% !important;
}
@media screen and (max-width:750px) {
  .videopart{
    display: flex !important;
    flex-direction: column !important;
  //  border:8px solid red  !important;
   gap:5vh !important;
  }
  .iframeVD{
    width: 100% !important;
    height: 100% !important;
    // border:3px solid red !important;
  }
} 
#scrollToTopButton {
  // display: none;
  // border:4px solid red !important;


  position: fixed;
  bottom: 20px;
  right: 50px;
  // background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px 20px;
  cursor: pointer;
  z-index: 99999;
  outline: none;
  @media screen and (min-width: 300px) and (max-width: 550px) {
    position: fixed;
    bottom: 20px;
    right: 10px;

  }

  .scrollup {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
}