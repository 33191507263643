.main-banner-wrap {
  background-image: url("../../assets//Images//SRFI-banner-image-01.jpg");
  height: 925px;
  background-position: bottom;
  background-repeat: no-repeat;
  // background-size: cover;
  background-size: 100% 925px;
  width: 100%;
  padding-top: 45px;

  @media screen and (min-width: 100px) and (max-width: 768px) {
    padding-top: 0px;
  }

  @media screen and (min-width: 300px) and (max-width: 550px) {
    width: 100%;
    height: 430px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% 430px;
  }

  @media screen and (min-width: 550px) and (max-width: 650px) {
    width: 100%;
    height: 545px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% 545px;
  }

  @media screen and (min-width: 650px) and (max-width: 675px) {
    width: 100%;
    height: 580px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% 580px;

    // border: 1px solid blue;
  }
  @media screen and (min-width: 675px) and (max-width: 800px) {
    width: 100%;
    height: 650px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% 650px;
  }

  @media screen and (min-width: 768px) and (max-width: 850px) {
    width: 100%;
    height: 700px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% 700px;

    // border: 1px solid red;
  }

  @media screen and (min-width: 800px) and (max-width: 1000px) {
    width: 100%;
    height: 670px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% 670px;
  }
  @media screen and (min-width: 1000px) and (max-width: 1200px) {
    width: 100%;
    height: 700px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% 700px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1400px) {
    width: 100%;
    height: 900px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% 900px;
  }

  .banner-container {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;

    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;

    .navbar2-container {
      border-radius: 5px;
      background: #f04a3e;
      height: 75px;
      width: 75%;
      padding-left: 10px;
      margin: 0 auto;
      position: relative;
      top: 13px;
      padding-right: 10px;

      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 95%;
        // border: 1px solid white;
      }

      @media screen and (min-width: 600px) and (max-width: 1000px) {
        width: 82%;
        // border: 1px solid white;
      }

      @media screen and (min-width: 1000px) and (max-width: 1200px) {
        width: 82%;
        // border: 1px solid white;
      }

      @media screen and (min-width: 1200px) and (max-width: 1400px) {
        width: 82%;
        // border: 1px solid white;
      }
      @media screen and (min-width: 1400px) and (max-width: 1550px) {
        width: 82%;
        // border: 1px solid white;
      }
    }

    .home-carousel {
      // height: 100%;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      // padding-right: 7%;
      // margin: 0 auto;
      z-index: 888;
      position: relative;

      // border: 1px solid white;
      height: 100%;
      top: 20px;

      @media screen and (min-width: 300px) and (max-width: 1200px) {
        position: relative;
        top: 10px;
      }

      .carousel {
        width: 100%;
        height: 100%;

        img {
          // border-radius: 1%;
          // opacity: 0.8;
          filter: drop-shadow(5px 5px 10px #000000);
        }
      }

      /* Custom styles for carousel control icons */
      .custom-carousel-control {
        color: #fff !important;
        font-size: 60px; /* Adjust the font size if needed */
      }

      .carousel-control-prev {
        z-index: 2;
        height: 85%;
        // border: 1px solid white;
        width: 9%;

        @media screen and (min-width: 300px) and (max-width: 650px) {
          height: 70%;

          height: 25%;
          // border: 1px solid;
          margin-top: 100px;
        }
      }

      .carousel-control-next {
        z-index: 2;
        height: 85%;
        // border: 1px solid white;
        width: 9%;

        @media screen and (min-width: 300px) and (max-width: 650px) {
          height: 70%;

          height: 25%;
          // border: 1px solid;
          margin-top: 100px;
        }
      }

      /* Apply the custom styles to the left and right controls */
      .carousel-control-prev-icon {
        // background-image: none;
        display: inline-block;
        width: 65px;
        height: 40px;
        // background: no-repeat 50%/100% 100%;

        // &::before {
        //   content: "‹"; /* Unicode character for the left arrow */
        //   /* Apply custom styles */
        //   @extend .custom-carousel-control;
        // }
      }

      .carousel-control-next-icon {
        // background-image: none;
        display: inline-block;
        width: 65px;
        height: 40px;
        // background: no-repeat 50%/100% 100%;

        // &::before {
        //   content: "›"; /* Unicode character for the right arrow */
        //   /* Apply custom styles */
        //   @extend .custom-carousel-control;
        // }
      }

      // .span.visually-hidden
      .visually-hidden {
        // color: black !important;
        // font-size: 600 !important;
        display: none;
      }

      .carousel-indicators {
        display: none;
      }

      .banner-content {
        color: #001d38;
        // font-family: Roboto;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        width: 100%;
        // border: 1px solid;
        margin: 0 auto;

        object-fit: fill;
        margin-right: 175px;

        @media screen and (min-width: 300px) and (max-width: 550px) {
          width: 95%;
          margin-right: 10px;
          font-size: 50px;
        }

        .banner-image {
          // height: 350px;
          // width: 500px;

          background-image: url("../../assets/Images/MinBanner/banner1.jpg");
          height: 775px;
          background-position: bottom;
          background-repeat: no-repeat;
          background-size: cover;
          background-size: 100% 775px;
          width: 75%;
          margin: 0 auto;
          // margin-top: 45px;

          @media screen and (min-width: 300px) and (max-width: 550px) {
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 380px;
            width: 100%;
            margin: 0 auto;
            // border: 1px solid red;

            // width: 358px;
            width: 100%;
            height: 308px;
          }

          @media screen and (min-width: 550px) and (max-width: 600px) {
            width: 100%;
            height: 430px;
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 430px;
            width: 95%;
            margin: 0 auto;

            // border: 1px solid greenyellow;
          }

          @media screen and (min-width: 600px) and (max-width: 650px) {
            width: 100%;
            height: 430px;
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 430px;
            width: 82%;
            margin: 0 auto;
          }

          @media screen and (min-width: 650px) and (max-width: 675px) {
            width: 100%;
            height: 450px;
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 450px;
            width: 82%;
            margin: 0 auto;
          }
          @media screen and (min-width: 675px) and (max-width: 800px) {
            width: 100%;
            height: 525px;
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 525px;
            width: 82%;
            margin: 0 auto;
          }

          @media screen and (min-width: 800px) and (max-width: 1000px) {
            width: 100%;
            height: 510px;
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 510px;
            width: 82%;
            margin: 0 auto;
          }
          @media screen and (min-width: 1000px) and (max-width: 1200px) {
            width: 100%;
            height: 540px;
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 540px;
            width: 82%;
            margin: 0 auto;
          }

          @media screen and (min-width: 1200px) and (max-width: 1400px) {
            width: 100%;
            height: 705px;
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 705px;
            width: 82%;
            margin: 0 auto;
          }

          @media screen and (min-width: 1400px) and (max-width: 1550px) {
            width: 100%;
            height: 755px;
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 755px;
            width: 82%;
            margin: 0 auto;
          }
        }

        .banner-video {
          height: 775px;
          background-position: bottom;
          background-repeat: no-repeat;
          background-size: cover;
          background-size: 100% 775px;
          width: 75%;
          margin: 0 auto;
          // margin-top: 45px;

          @media screen and (min-width: 300px) and (max-width: 550px) {
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 308px;
            width: 100%;
            margin: 0 auto;
            // border: 1px solid red;

            // width: 358px;
            width: 100%;
            height: 308px;
          }

          @media screen and (min-width: 550px) and (max-width: 600px) {
            width: 100%;
            height: 430px;
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 430px;
            width: 95%;
            margin: 0 auto;
          }

          @media screen and (min-width: 600px) and (max-width: 650px) {
            width: 100%;
            height: 430px;
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 430px;
            width: 82%;
            margin: 0 auto;
          }

          @media screen and (min-width: 650px) and (max-width: 675px) {
            width: 100%;
            height: 450px;
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 450px;
            width: 82%;
            margin: 0 auto;
          }
          @media screen and (min-width: 675px) and (max-width: 800px) {
            width: 100%;
            height: 525px;
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 525px;
            width: 82%;
            margin: 0 auto;
          }

          @media screen and (min-width: 800px) and (max-width: 1000px) {
            width: 100%;
            height: 510px;
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 510px;
            width: 82%;
            margin: 0 auto;
          }
          @media screen and (min-width: 1000px) and (max-width: 1200px) {
            width: 100%;
            height: 540px;
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 540px;
            width: 82%;
            margin: 0 auto;
          }

          @media screen and (min-width: 1200px) and (max-width: 1400px) {
            width: 100%;
            height: 705px;
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 705px;
            width: 82%;
            margin: 0 auto;
          }
          @media screen and (min-width: 1400px) and (max-width: 1550px) {
            width: 100%;
            height: 755px;
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            background-size: 100% 755px;
            width: 82%;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
