.TEAM-PART1 {
  // border: 1px solid red; 
  padding-left:  0px !important;
    padding-right: 0px !important;
  .TEAM_inner_wrapper {
    // border: 1px solid blue;
    background-image: url("../../../assets/Images/SRFIbannerimage-02.jpg");
    height: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;

    padding-top: 57px;

    .navbar2-container-TEAM {
      border-radius: 5px;
      background: #f04a3e;
      height: 75px;
      width: 71%;
      padding-left: 10px;
      margin: 0 auto;
      // position: relative;
      // top: 35px;
      padding-right: 10px;

      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 1300px) {
        width: 85%;
      }
    }

    .content-TEAM {
      width: 71%;
      height: 100%;
      background-color: #f5f4f4;
      color: #000;
      padding: 5px 15px !important;
      //  border:3px solid red ;
      margin: 0 auto !important;
      margin-top: 20px !important;

      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 1300px) {
        width: 85%;
      }
    }
  }
}

#scrollToTopButton {
  // display: none;
  // border:4px solid red !important;
  position: fixed;
  bottom: 20px;
  right: 50px;
  // background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px 20px;
  cursor: pointer;
  z-index: 99999;
  outline: none;

  @media screen and (min-width: 300px) and (max-width: 550px) {
    position: fixed;
    bottom: 20px;
    right: 10px;

  }
  .scrollup {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
}

.teamplaysCARDS {
  // border:9px solid black ;

  border-radius: 5px;
  // width: 100%;

  margin: 0 auto;

  @media screen and (min-width: 300px) and (max-width: 600px) {

    width: 100% !important;

  }
}
// .tabsTEAM{
//   border:2px solid red ;
// }
.paragraphTeamIndia {
  // border:3px solid red;
  color: #000;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  line-height: 25px;
  // border:2px solid red;
  padding: 2vh;
  margin-top: -4vh;
}

// -----------------------NAV------------------------
#MENUS {

  
  // border: 2px solid rgb(255, 0, 0) !important; 
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5vh;

}

@media screen and(max-width:458px) {
  #MENUS {
    width: 100% !important;
    margin-left: -3vh !important;
    // bordeR:3px solid black !important;
    flex-direction: column;
    gap: 2vh !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .ATAGGS{
    // border:3px solid red ;
    margin-left: 6vh !important;
  }

}

// --------------anchor ----Teamplay-------------------
.Teamplays {
  color: #000 !important;
  border: 12px solid black;
  outline: none;
  padding: none !important;
  text-decoration: none !important;
  font-size: 18px !important;
  border: none !important;
  font-weight: 400;
  // padding-bottom: 31px !important;
  background-color: transparent !important;

}

.nav-pills>li>.Teamplays {
  // width: 5vh !important;
  padding: none !important;
  background-color: transparent !important;

}

// ------------BORDER--- & ACNCHOR  -----------------------
.borderALL {
  display: flex;
  // padding-left: 2vh !important;
  width: 31vh !important;
  // border:3px solid red ;
  margin-top: 5px;
  margin-left: none !important;
}


#ALL {
  width: 4vh !important;

}

.tabBord {
  width: 10vh !important;

}

.ATAGGS {
  // bordeR:2px solid red ; 
  display: grid;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  z-index: 1; 
}


.nav-pills>li.active>.Teamplays>.borderALL.active {
  color: #000 !important;
  font-weight: bold; /* Add this to make the text bold */
  font-size: 16px; /* Add this to adjust the font size */
}

.Teamplays:hover {
  border: none !important;
}

.nav-pills>li.active>.Teamplays:hover> {
  color: #000 !important;
  border: none !important;
  text-decoration: none !important;
  background-color: transparent !important;
}

.nav-pills>li>span {
  text-align: center !important;
}



.nav-pills>li.active>a+.borderALL {
  border: 2px solid #ff0909 !important;
}


// ------------BORDER--- & ACNCHOR  -----------------------



.nav-pills>li .Teamplays {
  // border:2px solid red ;
  background-color: transparent;

}


.nav-pills>li.active>.Teamplays {
  color: #000 !important;
  font-weight: bold; /* Add this to make the text bold */
  font-size: 16px; /* Add this to adjust the font size */

}

#Menu>li>.Teamplays:hover {
  border: none !important;
}

.nav-pills>li>.Teamplays:hover {
  color: #000 !important;
  background-color: transparent !important;
  border: none !important;
}

.nav-pills>li>span {
  text-align: center !important;
}

#Menu>li>a>span.active {
  border: 3px solid #f04a3e !important;
  // border: none !important;

}

// ---------------CARDS--------------------------\

.ALLCARDSTEAM {
  // border:9px solid rgb(0, 0, 0) ;   
  // padding-left: 7vh;
  padding-right: 1px;

  grid-template-columns: 3fr 3fr 3fr;
  gap: 3vh;
  padding-top: 5vh;
  display: grid;
  text-align: center !important;
  justify-content: center !important;
  padding-bottom: 3vh !important;
  // align-items: center !important;

}
.Content-Card{
  // border:4px solid red ;
  display: flex;
  justify-content: center !important;
  align-items: center;
}
.TEAM {
  // border:5px solid red !important;
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 85% !important;
  // width: 80% !important;
  height: 90%;
  width: 250px;
  // margin-left: 4vh !important;
}

.cardtitle {
  // border:3px solid red ;   
  padding-bottom: 10px;
  font-size: 15px;
}

.cardALl {
  // border:3px solid blue !important;
  border-radius: 2vh !important;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2vh;

  // height: 90%;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);

}

@media screen and (max-width:992px) {
  .ALLCARDSTEAM {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    //  border:8px solid rgb(238, 58, 58)  !important;
    gap: 5vh !important;
    padding-left: 1vh !important;
  }

  .cardALl {
    width: 100% !important;
    height: 100% !important;
    // border:3px solid rgb(7, 7, 7) !important; 
  }

 
}

@media screen and (max-width:300px) {
  .TEAM {

    margin-left: none !important;
    // border: 3px soslid rgb(0, 0, 0) !important;
  }
}