.navrbar1_main-wrapper {
  width: 100%;
  height: 50px;
  //   border: 1px solid;
  background-color: #f04a3e;

  position: fixed;
  z-index: 999;

  @media screen and (min-width: 100px) and (max-width: 768px) {
    height: 20%;
    position: relative;

    // position: fixed;
    // z-index: 999;
  }

  .inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: 100px) and (max-width: 768px) {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 15px;

    }

    @media screen and (min-width: 100px) and (max-width: 768px) {
      height: 100%;
    }

    .nav-options {
      display: flex;
      justify-content: end;
      margin-right: 100px;
      //   margin-top: 6px;
      padding-top: 6px;
      gap: 20px;


      @media screen and (min-width: 300px) and (max-width: 500px) {
        height: 100%;
        margin: 0 auto;
      }

      @media screen and (min-width: 100px) and (max-width: 768px) {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        padding-bottom: 15px;
        margin-right: 0px;
      }

      .search-container {
        border: 1px solid #fff;
        width: 279px;
        height: 35px;
        border-radius: 5px;
        background: #fff;
        display: flex;

        // img {
        //   width: 15px;
        //   height: 17px;
        //   padding: 9px;
        // }

        svg {
          color: #f04a3e;
          width: 30px;
          height: 30px;
          padding: 3px;
        }

        .search-field {
          border: none;
          outline: none;
          width: 179px;
          height: 30px;
          border-radius: 5px;
          background: #fff;
          color: #f04a3e;
        }
        ::placeholder {
          color: #f04a3e;
        }
      }

      .time-login-wrap {
        display: flex;
        gap: 20px;

        @media screen and (min-width: 300px) and (max-width: 768px) {
          display: block;
          padding: 10px;
        }

        .squash-time-wrap {
          // border: 1px solid;
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          @media screen and (min-width: 300px) and (max-width: 768px) {
           margin: 10px;
          }

          img {
            height: 20px;
            width: 20px;
          }

          .squash-time {
            border: none;
            outline: none;
            background-color: transparent;
            cursor: pointer;
            padding: 0px;
            color: #e6e5e6;

            font-family: "Roboto";
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            @media screen and (min-width: 300px) and (max-width: 768px) {
              // margin: 10px;
             }
          }
        }

        .official-login-wrap {
          // border: 1px solid;
          display: flex;
          align-items: center;
          // gap: 5px;
          cursor: pointer;

          // img {
          //   height: 20px;
          //   width: 20px;
          // }

          svg {
            color: #fff;
            width: 30px;
            height: 30px;
            padding: 3px;
          }
          .official-login {
            border: none;
            outline: none;
            background-color: transparent;
            cursor: pointer;
            padding: 0px;
            color: #e6e5e6;

            //   font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }
    }
  }
}

.modal-backdrop {
  z-index: 0 !important;
}
.modal-header {
  border: none !important;
}
.modal-footer {
  border: none !important;
}


.National_Doubles{
  background: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, rgb(255, 255, 255));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 6s ease-in-out infinite;
  background-size: 400% 100%;
  font-weight: 600;
  font-size: 18px;    
 &:hover{
    color: transparent;

    text-decoration: none;
  }
}

@keyframes rainbow_animation {
  0%,100% {
      background-position: 0 0;
  }

  50% {
      background-position: 100% 0;
  }
}