.affiliation-main-wrapper {

  .indiasquash_inner_wrapper {
    // border: 1px solid blue;
    background-image: url("../../../assets/Images/SRFIbannerimage-02.jpg");
    height: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    // padding-top: 20px;
    padding-bottom: 20px;
    padding-top: 57px;

  .navbar2-container {
    border-radius: 5px;
    background: #f04a3e;
    height: 75px;
    width: 71%;
    padding-left: 10px;
    margin: 0 auto;
    // position: relative;
    // top: 35px;
    padding-right: 10px;

    @media screen and (min-width: 300px) and (max-width: 600px) {
      width: 100%;
    }

    @media screen and (min-width: 600px) and (max-width: 1300px) {
      width: 85%;

    }
  
  }

  .affiliation-inner {
    border-radius: 5px;
    width: 71%;
    height: 100%;
    background-color: #f5f4f4;
    color: #000;
    padding: 5px 15px;
    margin: 0 auto;
    margin-top: 20px;
    @media screen and (min-width: 300px) and (max-width: 600px) {
      width: 100%;
    }

    @media screen and (min-width: 600px) and (max-width: 1300px) {
      width: 85%;

    }

    .shimmer {
      margin-top: 20px;
        text-align: left;
      h4 {
        text-align: left;

        b {
          color: #f04a3e;
          // font-family: Roboto;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }

    .documnets-container {
      .consolidate-wrapper {
        margin-bottom: 20px;
        display: flex;

        .consolidate-btn {
          padding: 10px;
          border: none;
          outline: none;
          border-radius: 5px;
          // border: 1px solid rgba(0, 0, 0, 0.5);
          border: none;
          background: #fff;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          color: #000;
          //   font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

           
        }
        :hover {
          background-color: #f04a3e;
          color: #fff;
          border: none;
        } 
      }

      .inner-tabs {
        .subnavbar {
          display: flex;
          gap: 20px;
          background-color: #f0f0f0;
          padding: 10px;

          @media screen and (min-width: 250px) and (max-width: 500px) {
            display: block;
            // padding: 20px;
          }

          .tab {
            margin-right: 10px;
            position: relative; /* Set the position to relative */

            @media screen and (min-width: 250px) and (max-width: 500px) {
             margin: 20px;
            }
          }

          button {
            background-color: transparent;
            border: none;
            cursor: pointer;
            outline: none;
            font-size: 16px;
            color: #000;

            &.active {
              font-weight: bold;

              &::after {
                content: "";
                display: block;
                width: 100%; /* Adjust the width as needed */
                border-bottom: 3px solid #f04a3e; /* Adjust the thickness and color as needed */
                position: absolute;
                bottom: 0;
                left: 0;

                @media screen and (min-width: 250px) and (max-width: 500px) {     
                  content: "";
                  display: block;
                  width: 60%;
                  border-bottom: 3px solid #f04a3e;
                  position: absolute;
                  bottom: 0;
                  /* width: 100px; */
                  left: 20%;
              
                 }
              }
            }
          }

          button {
            background-color: transparent;
            border: none;
            cursor: pointer;
            outline: none;
            font-size: 16px;

            &.active {
              font-weight: bold;
            }
          }
        }

        .tab-content {
          margin-top: 10px;

          .govt-wrap {
            margin: 0 auto;
            width: 100%;
            // border: 1px solid;
            // padding: 30px;

            .card-container-aff {
              // max-width: 300px;

              margin-top: 50px;
              // width: 25%;
              // margin-bottom: 20px;

              .navigate-image {
                height: 250px;
              //  width: 250px;
              width: 95%;
                display: block;
                margin: 0 auto;

                .card {
                  height: 250px;
                 width: 250px;
                 width: 95%;
                  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                    0 6px 20px 0 rgba(0, 0, 0, 0.19);
                  transition: all 0.2s ease-in-out;
                  border-radius: 7%;
                  margin: 5px;

                  .pdf-image {
                    height: 150px;
                    width: 150px;
                    margin: 0 auto;
                    margin-top: 20px;
                  }

                  .card-body {
                    padding: 23px;

                    .card-text {
                      color: black;
                      // margin-bottom: 1px;
                      margin-top: -14px;
                      font-size: 16px;
                      text-align: center;
                    }
                  }
                }
              }
            }
          }
        }
        }
      }
    }
  }

  #scrollToTopButton {
    // display: none;
    position: fixed;
    bottom: 20px;
    right: 50px;
    // background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 99999;
    outline: none;

    @media screen and (min-width: 300px) and (max-width: 550px) {
      position: fixed;
      bottom: 20px;
      right: 10px;

    }

    .scrollup {
      height: 50px;
      width: 50px;
      border-radius: 50%;
    }
  }
}
