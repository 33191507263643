.contacus-main-wrapper {

  .indiasquash_inner_wrapper {
    // border: 1px solid blue;
    background-image: url("../../../assets/Images/SRFIbannerimage-02.jpg");
    height: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;

    padding-top: 57px;

  .navbar2-container {
    border-radius: 5px;
    background: #f04a3e;
    height: 75px;
    width: 71%;
    padding-left: 10px;
    margin: 0 auto;
    // position: relative;
    // top: 35px;
    padding-right: 10px;

    @media screen and (min-width: 300px) and (max-width: 600px) {
      width: 100%;
    }

    @media screen and (min-width: 600px) and (max-width: 1300px) {
      width: 85%;
    }
  }
  .contact-inner {
   
    width: 71%;
    height: 100%;
    background-color: #f5f4f4;
    color: #000;
    padding: 5px 15px;
    margin: 0 auto;
    margin-top: 20px;
    @media screen and (min-width: 300px) and (max-width: 600px) {
      width: 100%;
    }

    @media screen and (min-width: 600px) and (max-width: 1300px) {
      width: 85%;
    }

    .shimmer {
      margin-top: 20px;
      text-align: left;
      h4 {
        text-align: left;

        b {
          color: #f04a3e;
          // font-family: Roboto;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }

    .contact-container {
      display: flex;
      position: relative;
      margin-top: 1%;

      .addr-details {
        display: flex;
        flex-direction: column;
        max-width: 325px;
        height: 400px;

        h5 {
          font-weight: bold;
          font-size: 22px;
          text-align: left;
        margin-top: 25px;
        }

        .contact-details {
          color: #000;
          // font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          // line-height: normal;
          text-align: left;
        }


          a {
          margin-top: 20px;
            text-decoration: none;
            color: #000;
            // font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            // line-height: normal;
            text-align: left;
            border-bottom: 1px dotted #99cbdd;
          }
        
      }

      .addr {
        flex-direction: column;
        display: flex;
        // position: absolute;
        right: 44%;
        width: fit-content;
        height: 100%;
        margin-left: 3%;


        .point-img {
          width: 10px;
        }

        img {
          position: relative;
          left: 70px;
        }
      }

      @media (max-width: 1675px) {
        display: flex;
        flex-direction: column;

        .addr {
          display: flex;
          position: relative;
          right: 0;
          width: auto;
          height: auto;
        }

        .point-img {
          display: none;
        }
      }
    }
  }
}

}