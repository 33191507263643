.mini-banner-wrapper {
  // border: 1px solid;
  width: 100%;
  height: 262px;
  background-image: url("../../assets/Images/Rectangle2.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 262px;
  width: 100%;

  .mini-inner-container {
    // display: flex;
    // justify-content: center;
    // gap: 100px;
    // margin-top: 60px;

    .minibanner-carousel {
      // .content {
      //   width: 30%;
      //   //   border: 1px solid #fff;
      //   color: #fff;

      //   // .cont1 {
      //   //   display: flex;
      //   //   // border: 1px solid;

      //   //   .image-one {
      //   //     width: 20px;
      //   //     height: 40px;
      //   //     margin-top: 10px;
      //   //   }
      //   //   button {
      //   //     border: none;
      //   //     outline: none;
      //   //     background-color: transparent;

      //   //     color: #3a8cd5;
      //   //     //   font-family: Poppins;
      //   //     font-size: 45.691px;
      //   //     font-style: normal;
      //   //     font-weight: 700;
      //   //     line-height: normal;
      //   //   }
      //   //   .image-two {
      //   //     width: 20px;
      //   //     height: 40px;
      //   //     margin-top: 10px;
      //   //   }

      //   //   .date {
      //   //     margin-top: 15px;
      //   //     margin-left: 10px;
      //   //   }
      //   // }

      //   // .cont2 {
      //   //   display: flex;
      //   //   // border: 1px solid;

      //   //   span {
      //   //     color: #fff;
      //   //     //   font-family: Poppins;
      //   //     font-size: 28.557px;
      //   //     font-style: normal;
      //   //     font-weight: 600;
      //   //     line-height: normal;
      //   //   }

      //   //   button {
      //   //     border: none;
      //   //     outline: none;

      //   //     border-radius: 3.57px;
      //   //     background: #ee7c00;
      //   //     width: 100px;
      //   //     height: 21px;

      //   //     color: #fff;
      //   //     //   font-family: Poppins;
      //   //     font-size: 11.423px;
      //   //     font-style: normal;
      //   //     font-weight: 600;
      //   //     line-height: normal;
      //   //     margin-top: 5px;
      //   //     margin-left: 11px;
      //   //   }
      //   // }
      // }

      // .banner-group {

      //   display: flex;
      //   justify-content: center;
      //   gap: 20px;

      .banner-content {
        // border: 1px solid yellow;
        height: 260px;
        padding-top: 5px;

        .banner-img {
          width: 450px;
          height: 250px;

          @media screen and (min-width: 250px) and (max-width: 550px) {
            // border: 1px solid red;
            width: 350px;
            height: 235px;
            padding: 10px;
            margin-top: 9px;
          }
        }
      }

      // }

      .visually-hidden {
        // color: black !important;
        // font-size: 600 !important;
        display: none;
      }

      .carousel-control-prev,
      .carousel-control-next {
        display: none;
      }
    }
  }
}
