.procoach-ranking-wrapper {
  @media screen and (min-width: 100px) and (max-width: 868px) {
    height: auto;
  }

  .pro-inner-wrapper {
    .procoach-btn {
      border: none;
      outline: none;
      background-color: transparent;
      margin-top: 30px;

      color: #000;
      // font-family: Roboto;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 3.658px;
    }

    .pro-wrap {
      .row {
        .box-one {
          margin-top: 20px;
          .btn-wrap {
            margin: 0 auto;
            width: 75%;
            display: flex;
            justify-content: start;
            // border: 1px solid;
            margin-bottom: 10px;

            @media screen and (min-width: 300px) and (max-width: 768px) {
              width: 100%;
            }
            @media screen and (min-width: 668px) and (max-width: 768px) {
              width: 75%;
              margin: 0 auto;
            }

            @media screen and (min-width: 768px) and (max-width: 1250px) {
              width: 100%;
            }

            .pro-btn {
              border: none;
              outline: none;
              background-color: transparent;
              width: 225px;
              height: 44px;
              border-left: 5px solid #3a8cd5;
              border-radius: 5.08px 5.08px 0px 0px;
              background: #f04a3e;
              color: #fff;
              font-size: 24.384px;
              font-style: normal;
              font-weight: 700;
              text-align: left;
              padding: 5px;
              // margin: 0 auto;
              // margin-right: 333px;
            }
          }

          .pro-container {
            width: 75%;
            height: 150px;
            border-radius: 5px 5px 5px 5px;
            border: 1px solid rgba(0, 0, 0, 0.3);
            // opacity: 0.5;
            background: transparent;
            box-shadow: 0px 4.064056873321533px 4.064056873321533px 0px
              rgba(0, 0, 0, 0.25);
            padding: 10px;
            // border: 1px solid blue;
            margin: 0 auto;

            @media screen and (min-width: 300px) and (max-width: 400px) {
              width: 100%;
            }

            @media screen and (min-width: 400px) and (max-width: 668px) {
              width: 100%;
            }

            @media screen and (min-width: 768px) and (max-width: 1250px) {
              width: 100%;
            }

            .card-cont {
              // width: 450px;
              height: 75px;
              margin: 0 auto;
              margin-top: 10px;
              background-color: transparent;
              border-radius: 5.08px;
              border: 1.016px solid rgba(0, 0, 0, 0.1);
              background: transparent;
              box-shadow: 0px 4.064056873321533px 4.064056873321533px 0px
                rgba(0, 0, 0, 0.25);
              width: 90%;
              margin-top: 25px;

              display: flex;


              @media screen and (min-width: 300px) and (max-width: 400px) {
                width: 100%;
              }

              @media screen and (min-width: 400px) and (max-width: 668px) {
                width: 100%;
              }

              @media screen and (min-width: 768px) and (max-width: 1250px) {
                width: 100%;
              }

              .profile-details {
                // border: 1px solid green;
                display: flex;
                // justify-content: space-around;
                gap: 5px;
                width: 50%;

                .card-img-top {
                  width: 80px;
                  height: 80px;
                  border-radius: 50%;
                  box-shadow: 0px 4.06406px 4.06406px 0px rgba(0, 0, 0, 0.25);
                  // background-color: #000;
                  margin-top: -16px;
                  border: 1px solid rgba(0, 0, 0, 0.3);
                  float: left;
                  margin-left: 10px;
                }

                .name-venue {
                  width: 100%;
                  height: 100%;
                  //   border: 1px solid red;
                  padding-top: 10px;
                  padding-left: 5px;

                  .player-name {
                    color: #000;
                    font-size: 16.256px;
                    font-style: normal;
                    font-weight: 500;
                    //   line-height: normal;
                    display: flex;
                    align-items: center;
                    padding-left: 5px;
                    width: 200px;
                  }

                  .player-location {
                    color: #000;
                    font-size: 14.224px;
                    font-style: normal;
                    font-weight: 400;
                    //   line-height: normal;
                    display: flex;
                    align-items: center;
                    padding-left: 5px;
                  }
                }
              }

              .stats-details {
                display: flex;
                gap: 5px;
                // border: 1px solid red;
                width: 50%;
                padding-left: 10px;
                padding-top: 20px;
                justify-content: end;
                gap: 25px;
                @media screen and (min-width: 300px) and (max-width: 450px) {
                  gap: 0px;
                  //  border: 1px solid;
                 }

                @media screen and (min-width: 450px) and (max-width: 668px) {
                  gap: 5px;
                }

                @media screen and (min-width: 768px) and (max-width: 1250px) {
                  gap: 5px;
                }

                .cat-poit {
                  // border: 1px solid red;
                  // width: 60px;

                  .category {
                    display: flex;
                    align-items: center;
                    color: #f04a3e;
                    font-size: 16.256px;
                    font-style: normal;
                    font-weight: 500;
                  }

                  .points {
                    display: flex;
                    align-items: center;
                    color: #000;
                    font-size: 14.224px;
                    font-style: normal;
                    font-weight: 400;
                  }
                  @media screen and (min-width: 300px) and (max-width: 450px) {
                    width: 40px;
                   }
                }

                .rank-cont {
                  border: none;
                  outline: none;
                  background-color: transparent;
                  //   border: 1px solid;
                  position: relative;
                  bottom: 30px;
                  padding-bottom: 20px;
                  margin-right: 15px;
                  @media screen and (min-width: 300px) and (max-width: 450px) {
                    margin-right: 0px;
                   }

                  .rank {
                    display: block;
                    border-radius: 50%;
                    padding: 6px;
                    width: 33px;
                    height: 32px;
                    background-color: #fff;
                    box-shadow: 0px 2.0320284366607666px 4.064056873321533px 0px
                      rgba(0, 0, 0, 0.25) inset;
                    filter: drop-shadow(
                      0px 2.0320284366607666px 4.064056873321533px
                        rgba(0, 0, 0, 0.25)
                    );
                  }
                }
              }
            }
          }
        }

        .box-two {
          //   border: 1px solid red;

          .viewall-ranking-inner {
            height: 100%;
            align-items: center;
            display: flex;
            justify-content: center;
            padding-top: 50px;
            width: 50%;
            margin: 0 auto;

            .viewall-btn {
              border: none;
              outline: none;
              background-image: url("../../assets/Images/viewall.png");
              background-position: bottom;
              background-repeat: no-repeat;
              height: 80px;
              background-size: 100% 80px;
              width: 100%;
              display: flex;
              justify-content: end;
              align-items: center;

              .rank-text {
                color: #fff;
                // padding-left: 30px;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                // letter-spacing: 1.4px;
                // padding-right: 25px;
                // padding-bottom: 10px;
                width: 75%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 10px;

                // border: 2px solid;
              }

              .rank-cont {
                border: none;
                outline: none;
                background-color: white;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                box-shadow: 0px 2.03203px 4.06406px 0px rgba(0, 0, 0, 0.25)
                  inset;
                  margin-right: -15px;
                  margin-bottom: 10px;
                  filter: drop-shadow(0px 2.03203px 4.06406px rgba(0, 0, 0, 0.25));
                  display: flex;
                  align-items: center;
                  justify-content: center;

                .arrow-img {
                  height: 17px;
                  width: 17px;
                }
              }
            }
          }
        }
      }
    }
  }
}
