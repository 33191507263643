.upcoming-match-mainwrap {
  background-image: url("../../assets//Images/SRFIbannerimage-02.jpg");
  height: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  width: 100%;

  .upcoming-inner {
    padding-top: 70px;

    .latest-result-head {
      flex-direction: column;
      display: flex;
      align-items: center; /* Center items horizontally */
      // margin-left: 100px;
      padding-bottom: 50px;

      .coming-match-heading {
        border: none;
        outline: none;
        background-color: transparent;
        color: #000;
        //   font-family: Roboto;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 3.6px;
        margin-bottom: 10px;
        cursor: default;

        @media screen and (min-width: 100px) and (max-width: 500px) {
          color: #000;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          letter-spacing: 4px;
          // margin-top: 40px;

        }
      }
      .btn-border {
        height: 3px;
        width: 410px;

        @media screen and (min-width: 300px) and (max-width: 550px) {
          width: 260px;
          margin-left: 0px;
        }
      }
    }

    .container-fluid {
      .row {
        padding: 2rem 0rem;

        .card-outer {
          display: flex;
          justify-content: center;
          gap: 20px;

          @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
          }

          // @media (max-width: 968px) and (min-width: 768px) {
          //   flex-wrap: wrap;
          //   gap: 10px; // Adjust the gap as needed
          // }

          .card {
            cursor: pointer;
            padding: 2rem 0rem;
            width: 355px;
            height: 90%;
            border-radius: 4.851px;
            background: #e6e5e6;
            box-shadow: 0px 3.8805971145629883px 3.8805971145629883px 0px
                rgba(0, 0, 0, 0.25),
              0px 3.8805971145629883px 3.8805971145629883px 0px
                rgba(0, 0, 0, 0.25) inset;

            @media screen and (min-width: 300px) and (max-width: 450px) {
              width: 100% !important;
              // border: 1px solid;
              padding-bottom: 20px;
            }

            // @media (max-width: 968px) and (min-width: 768px) {
            //   height: 47%;
            //   margin-top: 40px;
            // }

            @media (max-width: 768px) {
              width: 75%; /* Adjust the width for smaller screens */
              margin-bottom: 35px;
            }

            .card-body {
              // border: 1px solid;
              padding-bottom: 10px;
              
              .img-cont {
                // display: flex;
                // flex-direction: column;
                // // border: 1px solid;
                // height: 100%;

                .logo {
                  width: 125px;
                  height: 125px;
                  border: 1px solid rgba(#fff, 0.3) !important;
                  margin: 0 auto;
                  margin-top: -6rem;
                  margin-bottom: 1rem;
                  // max-width: 9rem;
                  border-radius: 50%;
                  box-shadow: 0px 3.8805971145629883px 3.8805971145629883px 0px
                      rgba(0, 0, 0, 0.25),
                    0px 3.8805971145629883px 3.8805971145629883px 0px
                      rgba(0, 0, 0, 0.25) inset;

                  background: #e6e5e6;

                  .avatar {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    padding: 5px;
                  }
                }
              }

              .startlogo-div {
                width: 100%;
                // border: 1px solid;
                display: flex;
                justify-content: end;
                margin-top: -60px;
                margin-bottom: 25px;

                @media screen and (min-width: 300px) and (max-width: 550px) {
                  margin-bottom: 20px;

                }

                .starlogo {
                  width: 45px;
                  height: 45px;
                  border-radius: 50%;
                  background: #fff;
                  padding: 5px;
                }
              }

              .tournament-details {

                
                .tournament-title {
                  text-align: left;

                  @media screen and (min-width: 300px) and (max-width: 550px) {
                    text-align: center;
                  }
                  .card-title {
                    color: #000;
                    // font-family: Roboto;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 29.104px; /* 161.692% */
                    cursor: pointer;
                  }
                }

                .tournament-date {
                  display: flex;
                  align-items: center;
//                   @media screen and (min-width: 300px) and (max-width: 550px) {
// display: block;
//                   }

                  .calender-img {
                    width: 30px;
                    height: 30px;
                  }

                  .card-subtitle1 {
                    // color: #000;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    width: 100%;
                    // border: 1px solid;
                    text-align: left;
                    padding-left: 15px;
                  }
                }

                .tournament-venue {
                  display: flex;
                  align-items: center;
                  // @media screen and (min-width: 300px) and (max-width: 550px) {
                  //   display: block;
                  //                     }

                  .location-img {
                    width: 35px;
                    height: 35px;
                  }

                  .card-subtitle2 {
                    // color: #000;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    width: 100%;
                    // border: 1px solid;
                    text-align: left;
                    padding-left: 10px;
                    padding-top: 10px;
                  }
                }

                .tournament-category {
                  display: flex;
                  align-items: center;
//                   @media screen and (min-width: 300px) and (max-width: 550px) {
// display: block;
//                   }

                  .category-img {
                    width: 30px;
                    height: 30px;
                  }

                  .card-subtitle2 {
                    // color: #000;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    width: 100%;
                    // border: 1px solid;
                    text-align: left;
                    padding-left: 15px;
                    padding-top: 10px;
                  }
                }
              }
            }

            .register-wrap {
              // border: 1px solid;
              width: 100%;
              margin-top: 20px;
              padding-top: 20px;

              @media screen and (min-width: 300px) and (max-width: 550px) {
margin-top: 0px;
              }

              .register-date {
                color: #000;
                // font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  // @media screen and (min-width: 100px) and (max-width: 768px) {
  //   .card {
  //     width: 90% !important;
  //     // height: 200px !important;
  //     padding: 10px !important;

  //     .card-body {
  //       // border: 1px solid;
  //       display: flex;
  //       padding: 10px !important;

  //       .mob-img1 {
  //         // border: 1px solid;
  //         width: 100%;
  //         height: 100%;
  //       }

  //       .mob-img2 {
  //         // border: 1px solid;
  //         /* width: 100%; */
  //         height: 100%;
  //       }

  //       .logo {
  //         width: 100px !important;
  //         height: 100px !important;
  //         border: 0.3rem solid rgba(255, 255, 255, 0.3);
  //         max-width: 9rem !important;
  //         border-radius: 50% ;
  //         box-shadow: 0px 3.8806px 3.8806px 0px rgba(0, 0, 0, 0.25),
  //           0px 3.8806px 3.8806px 0px rgba(0, 0, 0, 0.25) inset;
  //         background: #e6e5e6;

  //         margin-top: 0px !important;
  //         .avatar {
  //           width: 100px !important;
  //         height: 100px !important;
  //           border-radius: 100px !important;
  //           padding: 5px;
  //         }
  //       }

  //       .startlogo-div {
  //         width: 100%;
  //         display: flex !important;
  //         justify-content: center !important;
  //         margin-top: 12px !important;

  //         .starlogo {
  //           width: 45px;
  //           height: 45px;
  //           border-radius: 50%;
  //           background: #fff;
  //           padding: 5px;
  //         }
  //       }

  //       .tournament-details {
  //         .tournament-title {
  //           text-align: left;
  //           .card-title {
  //             color: #000;
  //             // font-family: Roboto;
  //             font-size: 18px;
  //             font-style: normal;
  //             font-weight: 700;
  //             line-height: 29.104px; /* 161.692% */
  //           }
  //         }

  //         .tournament-date {
  //           display: flex;
  //           align-items: center;

  //           .calender-img {
  //             width: 30px;
  //             height: 30px;
  //           }

  //           .card-subtitle1 {
  //             // color: #000;
  //             font-size: 16px;
  //             font-style: normal;
  //             font-weight: 400;
  //             line-height: normal;
  //             width: 100%;
  //             // border: 1px solid;
  //             text-align: left;
  //             padding-left: 15px;
  //           }
  //         }

  //         .tournament-venue {
  //           display: flex;
  //           align-items: center;

  //           .location-img {
  //             width: 35px;
  //             height: 35px;
  //           }

  //           .card-subtitle2 {
  //             // color: #000;
  //             font-size: 16px;
  //             font-style: normal;
  //             font-weight: 400;
  //             line-height: normal;
  //             width: 100%;
  //             // border: 1px solid;
  //             text-align: left;
  //             padding-left: 10px;
  //             padding-top: 10px;
  //           }
  //         }

  //         .tournament-category {
  //           display: flex;
  //           align-items: center;

  //           .category-img {
  //             width: 30px;
  //             height: 30px;
  //           }

  //           .card-subtitle2 {
  //             // color: #000;
  //             font-size: 16px;
  //             font-style: normal;
  //             font-weight: 400;
  //             line-height: normal;
  //             width: 100%;
  //             // border: 1px solid;
  //             text-align: left;
  //             padding-left: 15px;
  //             padding-top: 10px;
  //           }
  //         }
  //       }

  //       .register-wrap {
  //         width: 100%;
  //         margin-top: 0px !important;
  //     }
  //     }
  //   }
  // }
}
