.balance-main-wrapper {
  .indiasquash_inner_wrapper {
    // border: 1px solid blue;
    background-image: url("../../../assets/Images/SRFIbannerimage-02.jpg");
    height: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;

    padding-top: 57px;

    .navbar2-container {
      border-radius: 5px;
      background: #f04a3e;
      height: 75px;
      width: 71%;
      padding-left: 10px;
      margin: 0 auto;
      // position: relative;
      // top: 35px;
      padding-right: 10px;

      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 1300px) {
        width: 85%;
      }
    }

    .balance-inner {
      width: 71%;
      height: 100%;
      background-color: #f5f4f4;
      color: #000;
      padding: 5px 15px;
      margin: 0 auto;
      margin-top: 20px;
      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 1300px) {
        width: 85%;
      }
      .shimmer {
        margin-top: 20px;
        text-align: left;
        h4 {
          text-align: left;

          b {
            color: #f04a3e;
            // font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }

      .balance-btns-wrapper {
        margin-bottom: 20px;
      }

      .accordion-item {
        border: none;
        padding: 0.75rem 1.25rem;
        width: 80%;
        height: 100%;
        padding: 10px;
        padding-left: 20px;
        // margin-top: 15px;

        @media screen and (min-width: 300px) and (max-width: 768px) {
          width: 100%;
          padding: 0px;
          margin-top: 25px;
        }
      }

      .accordion-item button {
        font-size: 18px;
        color: black;
        border-radius: 12px;
        cursor: pointer;

        text-align: left;
        width: 80%;
        height: 100%;
        padding: 15px;
        padding-left: 20px;
        border-radius: 5px;
        //   border: 1px solid rgba(0, 0, 0, 0.5);
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }

      .accordion-button:hover {
        color: #fff;
        text-align: left;
        width: 80%;
        height: 100%;
        padding: 15px;
        padding-left: 20px;
        border-radius: 5px;
        //   border: 1px solid rgba(0, 0, 0, 0.5);
        background: #f04a3e;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }

      .accordion-button:focus {
        color: #000;
        text-align: left;
        width: 80%;
        height: 100%;
        padding: 15px;
        padding-left: 20px;
        border-radius: 5px;
        //   border: 1px solid rgba(0, 0, 0, 0.5);
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }

      .accordion-button:not(.collapsed) {
        color: #fff;
        text-align: left;
        width: 80%;
        height: 100%;
        padding: 15px;
        padding-left: 20px;
        border-radius: 5px;
        //   border: 1px solid rgba(0, 0, 0, 0.5);
        background: #f04a3e;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        :focus {
          outline: none;
          border: none;
        }
      }

      .accordion-body .inner-btn-amg {
        font-size: 16px;
        color: black;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 6px 6px 12px #b8b9be, -6px -7px 10px #fff !important;
        border: none;
        outline: none;
        padding: 13px;
      }
    }
  }

  #scrollToTopButton {
    // display: none;
    position: fixed;
    bottom: 20px;
    right: 50px;
    // background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 99999;
    outline: none;

    @media screen and (min-width: 300px) and (max-width: 550px) {
      position: fixed;
      bottom: 20px;
      right: 10px;

    }

    .scrollup {
      height: 50px;
      width: 50px;
      border-radius: 50%;
    }
  }
}
