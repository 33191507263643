.NavBar_Main {
  /* border: 2px solid blue; */
  height: 100%;
  width: 100%;
  /* position: relative; */
  /* opacity: 1; */
  /* z-index: 4; */
}

.header {
  /* position: relative; */
  width: 100%;
  height: 100%;
  /* z-index: 3; */
  /* border: 1px solid green; */
}

.srfi-logo {
  height: auto;
  width: 240px;
}

@media screen and (min-width: 300px) and (max-width: 600px) {
  .srfi-logo {
    /* order: 1px solid; */
    width: 184px;
  }
}

.header .menu {
  /* border: 1px solid; */
  margin: 0;
  background: #f04a3e;
  padding: 0;
  list-style: none;
  text-align: left;
}

.header li a {
  display: block;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto";
  color: #fff;
  padding: 1rem;
  /* border: 1px solid brown; */

  @media screen and (min-width: 300px) and (max-width: 600px) {
    display: block;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    font-family: "Roboto";
    color: #fff;
    padding: 1rem;
    

  }
}

.header li a:hover,
.header .menu-btn:hover {
  background-color: #fff;
  color: #f04a3e;
}

.header li .nav-link {
  display: block;
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto";
  color: #fff;
  cursor: pointer;
}
.header li .nav-link:hover,
.header .menu-btn:hover {
  background-color: #fff;
  color: #f04a3e;
}


.header .logo {
  display: block;
  float: left;
  font-size: 2em;
  font-family: "Roboto";
  padding: 10px 10px;
  text-decoration: none;
}

/* menu */

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;

  /* height: 100%; */
  display: flex;
  align-items: center;
  height: 75px !important;

  /* border: 1px solid blue; */
}

/* menu icon */
.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 25px 15px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #fff;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

svg {
  width: 35px !important;
  height: 35px !important;
  color: #fff;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #fff;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
  /* font-size: 24px !important; */
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 100%;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}



.menu .nav-link {
  position: relative;
}

.menu .nav-link.active:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  border: 1px solid #ffffff !important;
  background-color: #ffffff;
  border-radius: 10px;
}
.menu .drop-part.active{
  background-color: #fff;
  color: #f04a3e;

}

@media (max-width: 95em) {
  .menu .nav-link.active:after {
    left: 50%;
    transform: translateX(-50%);
    width: 30%;
    border-radius: 5px;
  }

  .menu {
    display: none !important;
  }

  .menu-btn:checked ~ .menu {
    display: block !important;
    width: 100% !important;
    height: 100% !important;
    text-align: center;
    z-index: 998;
    position: relative;
  }

  .dropdown {
    position: relative !important;
    display: block !important;

  }
}



@media screen and (min-width: 1520px) and (max-width: 1535px) {
  .menu .nav-link.active:after {
    left: 50%;
    transform: translateX(-50%);
    width: 30%;
    border-radius: 5px;
  }

  .menu {
    display: none !important;
  }

  .menu-btn:checked ~ .menu {
    display: block !important;
    width: 100% !important;
    height: 100% !important;
    text-align: center;
    z-index: 998;
    position: relative;
  }

  .dropdown {
    position: relative !important;
    display: block !important;

  }
}


/* 96em = 1536px */
/* 93em = 1488px */


@media (min-width: 96em) {
  .header li {
    float: left;
  }

  .header li a {
    padding: 10px 10px;
  }

  .header .menu {
    clear: none;
    float: right;
    max-height: none;
    gap: 25px;
  }

  .header .menu-icon {
    display: none;
  }
}

/* Dropdown Style */
.dropdown {
  position: relative;
  display: inline-block;


  .active {
    // color: #ed4d00;
    // background-color: #f0f0f0;
  }

}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ed4d00;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 99999;
  top: 79px;
  left: 50%;
  transform: translateX(-50%);
}


@media (max-width: 96em) {
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ed4d00;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 99999;
    top: 0px;
    left: 70%;
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 300px) and (max-width: 600px) {
  .dropdown-content {
    position: absolute;
    background-color: #f04a3e;
    min-width: 135px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 99999;
    top: 40px;
    left: 80%;
    transform: translateX(-50%);
    
  }
}

@media screen and (min-width: 600px) and (max-width: 800px) {
  .dropdown-content {
    position: absolute;
    background-color: #f04a3e;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 99999;
    top: 40px;
    left: 70%;
    transform: translateX(-50%);
    
  }
}

@media screen and (min-width: 1536px) and (max-width: 1536px) {
      .dropdown-content {
    position: absolute;
    background-color: #f04a3e;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 99999;
    top: 78px;
    left: 50%;
    transform: translateX(-50%);
    
  }
}

/* Triangle shape */
.dropdown-caret {
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 18px 14px;
  border-style: solid;
  border-color: transparent transparent #f04a3e transparent;
  /* display: none; */
}

@media (max-width: 90em) {
  .dropdown-caret {
    /* position: absolute;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 18px 14px;
    border-style: solid;
    height: 30px;
    width: 70px;
    border-color: transparent transparent blue transparent;
    display: none; */
  }
}

/* Show the caret when hovering over .dropdown */
.dropdown:hover .dropdown-caret {
  display: block;
}

.dropdown-caret2 {
  position: absolute;
  top: 58px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 18px 21px;
  border-style: solid;
  top: 40px;
  border-width: 18px 18px 21px;
  border-color: transparent transparent #f04a3e transparent;
  display: none;
  z-index: 999;
}


@media (max-width: 90em) {
  .dropdown-caret2 {
    // position: absolute;
    // top: 58px;
    // left: 50%;
    // transform: translateX(-50%);
    // border-width: 0 18px 21px;
    // border-style: solid;
    // top: 40px;
    // border-width: 18px 18px 21px;
    // border-color: transparent transparent greenyellow transparent;
    display: none !important; 
  }
}

@media screen and (min-width: 1440px) and (max-width: 1534px) {
  .dropdown-caret2 {
    display: none !important; 
  }

}

/* Show the caret when hovering over .dropdown */
.dropdown:hover .dropdown-caret2 {
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-inner {
  /* border: 1px solid white; */
  height: 100%;
  width: 100%;
}

.dropdown-list {
  /* border: 1px solid; */
  width: 100%;
  height: 100%;
}

.dropdown-option {
  cursor: pointer;
  /* color: #fff !important; */
}
