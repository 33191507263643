.srfi-official-banner-wrap {
  //   border: 1px solid green;
  background-image: url("../../assets/Images/powerd-banner.png");
  height: 300px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 300px;
  width: 100%;

  @media screen and (min-width: 100px) and (max-width: 1200px) {
    height: auto;
    width: 100%;
    background-size: 100% 530px;
  }

  .inner-wrap {
    display: flex;
    height: 100%;

    @media screen and (min-width: 100px) and (max-width: 1200px) {
      display: block;
      // border: 1px solid white;
    }

    .row {
      .srfi-box {
        //   border: 1px solid white;
        text-align: left;
        margin: 0 auto;
        height: 100%;

        @media screen and (min-width: 300px) and (max-width: 750px) {
          text-align: center;
          // border: 1px solid white;
        }

        .srfi-cont {
          // border: 1px solid red;
          width: 50%;
          margin: 0 auto;
          margin-top: 90px;

          @media screen and (min-width: 300px) and (max-width: 450px) {
            width: 70%;
            margin: 0 auto;
            margin-top: 50px;
          }
          @media screen and (min-width: 450px) and (max-width: 750px) {
            width: 60%;
            margin: 0 auto;
            margin-top: 50px;
                    }
          @media screen and (min-width: 750px) and (max-width: 800px) {
            width: 45%;
            margin: 0 auto;
            margin-top: 50px;

            // border: 1px solid white;
          }
          @media screen and (min-width: 768px) and (max-width: 1000px) {
            width: 60%;
            margin: 0 auto;
            margin-top: 30px;
          }
      

          .srfifull-logo {
            width: 225px;
            height: 49px;
          }

          .srfi-official {
            color: #fff;
            // font-family: Roboto;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media screen and (min-width: 300px) and (max-width: 500px) {
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              padding-top: 20px;

              // color: green;
            }
            @media screen and (min-width: 500px) and (max-width: 768px) {
              font-size: 28px;
              // color: red;
              font-style: normal;
              font-weight: 600;
              padding-top: 20px;
            }

            @media screen and (min-width: 768px) and (max-width: 1000px) {
              font-size: 30px;
              // color: red;
              font-style: normal;
              font-weight: 600;
              padding-top: 20px;
            }

            @media screen and (min-width: 1000px) and (max-width: 1440px) {
              font-size: 34px;
              // color: red;
              font-style: normal;
              font-weight: 600;
              padding-top: 20px;
            }
          }
        }
      }

      .codoid-box {
        //   border: 1px solid yellow;
        margin: 0 auto;
        //   display: flex;

        .codoid-cont {
          // margin-top: 90px;
          // display: flex;

          margin: 0 auto;
          display: flex;
          justify-content: center;
          // border: 1px solid white;
          height: 100%;
          align-items: center;

          @media screen and (min-width: 100px) and (max-width: 550px) {
            margin-top: 0px;
            margin-bottom: 100px;
            padding-bottom: 50px;
          }

          @media screen and (min-width: 100px) and (max-width: 1200px) {
            // margin-top: 50px;
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
          }

          .app-play-store {
            // border: 1px solid white;
            display: flex;
            flex-direction: column;

            .dowloadapp-logo {
              // width: 199px;
              // height: 118px;
              // border: 1px solid;
              border-radius: 30px;
            }
            .google-btn {
              outline: none;
            }
            .app-btn {
              outline: none;
            }
          }

          .power-codoid {
            // border: 1px solid wheat;
            padding-top: 25px;

            .powerdby {
              color: #fff;
              //   font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .codoid-logo {
            }
          }
        }
      }
    }
  }
}
