.latestnews-mainwrapper {
  //   border: 1px solid green;
  background-image: url("../../assets//Images/SRFIbannerimage-02.jpg");
  height: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  padding-top: 20px;

  .container-inner-wrap {
    width: 100%;
    height: 100%;
    // border: 1px solid blue;
    padding: 10px;

    width: 100%;
    height: 100%;
    padding: 10px;
    margin: 0 auto;

    .latest-result-head {
      flex-direction: column;
      display: flex;
      align-items: center; /* Center items horizontally */
      // margin-left: 100px;
      padding-bottom: 50px;

      .ranking-btn {
        border: none;
        outline: none;
        background-color: transparent;
        margin-top: 20px;
        color: #000;
        // font-family: Roboto;
        font-size: 36.577px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 3.658px;
        cursor: default;
        margin-bottom: 10px;

        display: flex;
        margin-left: 25px;

        @media screen and (min-width: 100px) and (max-width: 500px) {
          color: #000;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          letter-spacing: 3.6px;
          // margin-top: 25px;
          margin-bottom: 5px;
          margin-left: 0px;
        }
      }

      .btn-border {
        height: 3px;
        // width: 232px;

        width: 275px;
          margin-left: 20px;

          @media screen and (min-width: 300px) and (max-width: 550px) {
            width: 170px;
            margin-left: 0px;
          }
      }
    }

    .news-data-wrapper {
      // border: 1px solid green;
      height: 100%;
      width: 100%;
      .latest-result-head {
        flex-direction: column;
        display: flex;
        align-items: center; /* Center items horizontally */
        // margin-left: 100px;
        padding-bottom: 0px;

        .ranking-btn {
          border: none;
          outline: none;
          background-color: transparent;
          margin-top: 20px;
          color: #000;
          // font-family: Roboto;
          font-size: 36.577px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 3.658px;
          margin-bottom: 10px;
          cursor: default;

          display: flex;
          margin-left: 25px;

          @media screen and (min-width: 100px) and (max-width: 500px) {
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 3.6px;
            // margin-top: 25px;
            margin-bottom: 5px;
            margin-left: 0px;
          }
        }

        .btn-border {
          height: 3px;
          // width: 233px;

          width: 275px;
          margin-left: 20px;

          @media screen and (min-width: 300px) and (max-width: 550px) {
            width: 170px;
            margin-left: 0px;
          }
        }
      }

      .carousel-item {
        // border: 1px solid;
        width: 100%;
        height: 100%;
        display: block;
        cursor: pointer;


        .news-img {
          // border: 1px solid;
          width: 400px;
          height: 300px;
          padding: 5px;
          margin: 0 auto;

          @media screen and (min-width: 100px) and (max-width: 1250px) {
            width: 100%;
          }
        }

        .news-title {
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          text-align: center;
          // padding-left: 20px;
          padding-top: 5px;
          width: 100%;
          // border: 1px solid;
        }

        .news-date {
          color: #000;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          text-align: center;
          // padding-left: 20px;
        }

        .visually-hidden {
          // color: black !important;
          // font-size: 600 !important;
          display: none;
        }

        .carousel-control-prev,
        .carousel-control-next {
          color: #000 !important;
        }
      }

      .carousel-item-video {
        // border: 1px solid green;
        overflow: hidden; /* Hide overflow to prevent horizontal scroll */

        .video-container {
          // display: flex;
          // flex-wrap: wrap;
          // justify-content: space-evenly; /* Center videos horizontally */

          .carousel-itemss {
            width: 100%; /* Make each video take up 100% of the container */
            max-width: 475px; /* Limit the maximum width of each video */
            margin: 20px; /* Adjust margin for spacing between videos */

            @media screen and (min-width: 100px) and (max-width: 768px) {
              margin: 0px;
              margin-top: 20px;
            }
            .responsive-video {
              width: 100%;
              height: 0;
              padding-bottom: 56.25%; /* 16:9 aspect ratio (9 / 16 * 100%) */
            }
          }
        }
      }

      @media (max-width: 768px) {
        .carousel-itemss {
          max-width: 100%; /* Allow videos to take full width on smaller screens */
        }
      }
    }
  }
}
