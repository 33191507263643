// --------------SEARCH-PART----------------
.selectionLI.selectionA {
  border: 6px solid rgb(0, 0, 0);
}

.header .selectionLI.selectionA {
  border: 6px solid rgb(0, 0, 0);
}

.SELECTION-PART1 {
  // border: 1px solid red;
  padding-left: 0px !important;
  padding-right: 0px !important;

  .SELECTION_inner_wrapper {
    // border: 1px solid blue;
    background-image: url("../../../assets/Images/SRFIbannerimage-02.jpg");
    height: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    // padding-bottom: 20px;
    padding: 0px;
    padding-top: 57px;

    .navbar2-container-1 {
      border-radius: 5px;
      background: #f04a3e;
      height: 75px;
      width: 70%;
      padding-left: 10px;
      margin: 0 auto;
      // position: relative;
      // top: 35px;
      padding-right: 10px;

      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 1300px) {
        width: 85%;
      }
    }

    .content-SELECTION1 {
      width: 70%;
      height: 100%;
      background-color: #f5f4f4;
      color: #000;
      padding: 5px 15px !important;
      //  border:3px solid red ;
      margin: 0 auto !important;
      margin-top: 20px !important;

      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 1300px) {
        width: 85%;
      }
    }
  }
}

#Selection-ContentACCORDION {
  margin-top: -3vh !important;
  // border:2px solid red ;
  padding: 2vh;
}

// li.a {
//   background-color: #000 !important;
//   bordeR: 5px solid red !important;
// }

// ---------------Accordions------------------

// .accordion-item {
//   border: none;
//   width: 100%;
// }

// .accordion {
//   width: 90%;
//   // border:2px solid green ;
// }

// .SECOND-PART {
//   margin-top: 13px !important;
// }

// .accordion-item,
// .accordion-item:focus,
// .accordion-item:active,
// .accordion-header,
// .accordion-header:focus,
// .accordion-header:active,
// .accordion-button,
// .accordion-button:focus,
// .accordion-button:active {
//   outline: none !important;
//   line-height: 9px;
//   @media screen and(min-width:300px)and(max-width:769px) {
//     line-height: 22px !important;

//   }
// }

// .accordion-item button {
//   font-size: 18px;
//   color: black;
//   border-radius: 12px;
//   cursor: pointer;
//   width: 100%;
//   text-align: left;
//   border-radius: 5px;
//   border: 1px solid rgba(0, 0, 0, 0.5);
//   background: #fff;
//   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

// }

// .accordion_items {
//   height: 4% !important;
//   margin-top: 5px !important;
// }

// #accordionExample {
//   // bordeR:3px solid red;
//   width: 100%;
// }

// .accordion-button:hover {
//   color: #000;
//   text-align: left;
//   border-radius: 5px;
//   //   border: 1px solid rgba(0, 0, 0, 0.5);
//   background: #f04a3e;
//   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

// }

// .accordion-button:focus {
//   color: #000;
//   text-align: left;
//   border-radius: 5px;
//   //   border: 1px solid rgba(0, 0, 0, 0.5);
//   background: #fff;
//   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

// }

// .accordion-button:not(.collapsed) {
//   color: #000000;
//   text-align: left;
//   border-radius: 5px;
//   border: 1px solid rgba(0, 0, 0, 0.5);
//   // background: #f04a3e;
//   // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

//   :focus {
//     outline: none;
//     border: none;
//   }
// }
// .accordion-body {
//   width: 100%;
//   background-color: transparent;

// }

.accordion-item {
  border: none;
  padding: 0.75rem 1.25rem;
  width: 80%;
  height: 100%;
  padding: 10px;
  padding-left: 20px;
  // margin-top: 15px;

  @media screen and (min-width: 300px) and (max-width: 450px) {
    width: 100%;
    padding: 0px;
    margin-top: 25px;
  }
  @media screen and (min-width: 450px) and (max-width: 900px) {
    width: 100%;
  }
}

.accordion-item button {
  font-size: 18px;
  color: black;
  border-radius: 12px;
  cursor: pointer;

  text-align: left;
  width: 80%;
  height: 100%;
  padding: 15px;
  padding-left: 20px;
  border-radius: 5px;
  //   border: 1px solid rgba(0, 0, 0, 0.5);
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  @media screen and (min-width: 300px) and (max-width: 450px) {
    width: 94%;
  }
  @media screen and (min-width: 450px) and (max-width: 900px) {
    width: 97%;
  }
}

.accordion-button:hover {
  color: #fff;
  text-align: left;
  width: 80%;
  height: 100%;
  padding: 15px;
  padding-left: 20px;
  border-radius: 5px;
  //   border: 1px solid rgba(0, 0, 0, 0.5);
  background: #f04a3e;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media screen and (min-width: 300px) and (max-width: 450px) {
    width: 94%;
  }
  @media screen and (min-width: 450px) and (max-width: 900px) {
    width: 97%;
  }
}

.accordion-button:focus {
  color: #000;
  text-align: left;
  width: 80%;
  height: 100%;
  padding: 15px;
  padding-left: 20px;
  border-radius: 5px;
  //   border: 1px solid rgba(0, 0, 0, 0.5);
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media screen and (min-width: 300px) and (max-width: 450px) {
    width: 94%;
  }
  @media screen and (min-width: 450px) and (max-width: 900px) {
    width: 97%;
  }
}

.accordion-button:not(.collapsed) {
  color: #fff;
  text-align: left;
  width: 80%;
  height: 100%;
  padding: 15px;
  padding-left: 20px;
  border-radius: 5px;
  //   border: 1px solid rgba(0, 0, 0, 0.5);
  background: #f04a3e;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media screen and (min-width: 300px) and (max-width: 450px) {
    width: 94%;
  }
  @media screen and (min-width: 450px) and (max-width: 900px) {
    width: 97%;
  }

  :focus {
    outline: none;
    border: none;
  }
}

.accordion-body .inner-btn-amg {
  font-size: 16px;
  color: black;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 6px 6px 12px #b8b9be, -6px -7px 10px #fff !important;
  border: none;
  outline: none;
  padding: 13px;
}

.cards-PARTS {
  margin-top: none !important;
  height: 7% !important;
  // background-color: #ac3518 !important;
}

// .accordion-body .inner-btn-amg {
//   background-color: transparent;
//   outline: none;
//   font-size: 16px;
//   color: black;
//   width: 100%;
//   // height: 4% !important;
//   border-radius: 5px;
//   cursor: pointer;
//   box-shadow: 6px 6px 12px #b8b9be, -6px -7px 10px #fff !important;
//   border: none;
//   outline: none;
//   padding: 1px;
//   margin-bottom: 1px;
// }
.NORMAL-CONTENT-PDF {
  height: 4% !important;
  padding: 10px !important;
  // bordeR:3px solid green !important;
}
// .SIZE_ACCORDION_CPart{
//  padding: 15px !important;
// }
// .normalpdf {
//   height: 4% !important;
// }
// // -----------FIRST-CRITERIa-----------

// #championshipsplayers {
//   // width: 100%;
//   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
//   border-radius: 1vh !important;
//   text-align: start;
//   cursor: pointer;
//   background-color: transparent;
//   outline: none;
//   width: 100%;
//   // border:12px solid red;
//   border: none;
// }

// #AsianGame {
//   background-color: transparent;
//   outline: none;
//   width: 100%;
//   // border:2px solid red;
//   border: none;
//   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
//   border-radius: 1vh !important;
//   text-align: start;
//   cursor: pointer;
// }

// //PDFS-------------------

// // ------------------BACTCH-BASED--------------
// #BATCH-BASED {
//   width: 80% !important;
//   margin-top: 1vh;
//   height: 100%;
//   text-align: center;
//   color: #000000;
//   // bordeR:4px solid red ;
//   background-color: #fff;

// }

// #BATCH-BASED:hover {
//   background-color: #f04a3e;
//   color: #fff;
// }

// .fontSTYLES {
//   font-size: 18px;
//   font-family: Roboto;
//   font-weight: 500;
// }

.normalpdfs {
  margin-top: -10px;
  // margin-bottom: 10px;
  // padding: 20px;
  padding-left: 20px;

  @media screen and (min-width: 300px) and (max-width: 450px) {
    padding-left: 0px;
    // margin-top: 15px;
  }
}
.HOVERCARDS {
  // margin: 20px;
  margin-top: 30px;

  font-size: 18px;
  color: black;
  border-radius: 12px;
  cursor: pointer;
  text-align: left;
  width: 63.5%;
  height: 100%;
  padding: 3px;
  padding-left: 20px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media screen and (min-width: 300px) and (max-width: 500px) {
    width: 94%;
  }
  @media screen and (min-width: 500px) and (max-width: 900px) {
    width: 95%;
  }

  @media screen and (min-width: 900px) and (max-width: 1200px) {
    width: 62.5%;
  }

  @media screen and (min-width: 1200px) and (max-width: 1500px) {
    width: 63%;
  }
}
// .selectionHeading {
//   // border:2px solid red;
//   text-align: start;
//   padding-left: 3vh;

// }

.HOVERCARDS:hover {
  background-color: #f04a3e;
  color: whitesmoke;
  gap: 3px !important;
}

// .normalpdf {
//   margin-top: -2vh;

// }

// @media screen and (max-width:780px) {
//   #Selection-Content {
//     padding: 1vh !important;
//   }

//   .accordion {
//     width: 104% !important;
//   }

//   #BATCH-BASED {
//     width: 104% !important;
//   }

//   .SECOND-PART {
//     margin-top: none !important;
//   }
// }
