.HCL-PART1 {
  // border: 1px solid red; 
  padding-left:  0px !important;
    padding-right: 0px !important;
  .HCL_inner_wrapper {
    // border: 1px solid blue;
    background-image: url("../../../assets/Images/SRFIbannerimage-02.jpg");
    height: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;

    padding-top: 57px;

    .navbar2-container-HCL {
      border-radius: 5px;
      background: #f04a3e;
      height: 75px;
      width: 71%;
      padding-left: 10px;
      margin: 0 auto;
      // position: relative;
      // top: 35px;
      padding-right: 10px;

      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 1300px) {
        width: 85%;
      }
    }

    .content-HCL {
      width: 71%;
      height: 100%;
      background-color: #f5f4f4;
      color: #000;
      padding: 5px 15px !important;
      //  border:3px solid red ;
      margin: 0 auto !important;
      margin-top: 20px !important;

      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 1300px) {
        width: 85%;
      }
    }
  }
}





.paragraphHCL {
  color: #000;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  line-height: 25px;
  //  border:2px solid red;
  padding: 15px;
  margin-top: -20px !important;
}