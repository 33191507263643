// --------------------i-SPIN-----Table-----------------------------

.ISPIN-PART1 {
    // border: 1px solid red; 
    padding-left:  0px !important;
    padding-right: 0px !important;

    .ISPIN_inner_wrapper {
        // border: 1px solid blue;
        background-image: url("../../../assets/Images/SRFIbannerimage-02.jpg");
        height: 100%;
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;

        padding-top: 57px;

        .navbar2-container-ISPIN {
            border-radius: 5px;
            background: #f04a3e;
            height: 75px;
            width: 71%;
            padding-left: 10px;
            margin: 0 auto;
            // position: relative;
            // top: 35px;
            padding-right: 10px;

            @media screen and (min-width: 300px) and (max-width: 600px) {
                width: 100%;
            }

            @media screen and (min-width: 600px) and (max-width: 1300px) {
                width: 85%;
            }
        }

        .content-ISPIN {
            width: 71%;
            height: 100%;
            background-color: #f5f4f4;
            color: #000;
            padding: 5px 15px !important;
            //  border:3px solid red ;
            margin: 0 auto !important;
            margin-top: 20px !important;

            @media screen and (min-width: 300px) and (max-width: 600px) {
                width: 100%;
            }

            @media screen and (min-width: 600px) and (max-width: 1300px) {
                width: 85%;
            }
        }
    }
}

.tableparts {
    // border:11px solid rgb(247, 255, 5);
    display: flex;

}

// .tab1 {
//     // border:2px solid red ;
// }

.paragraphI-Spin {
    color: #000;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    line-height: 25px;
    // border:2px solid red;
    padding-left: 1vh;
    padding-right: 1vh;
    padding-top: 1vh;
}

b {
    font-weight: 600;
}

.paragraphIspin {
    color: #000;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    line-height: 25px;
    // border:2px solid red;
    padding: 2vh;
    margin-top: -4vh;

}
.table-RESPONSIVES{
    // border:4px solid green !important;  
    width: 90%;
}
.ISPIN-TABLE{
    width: 90%;
    margin-left: 9vh;
}

.table-contents {
    //   border:4px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
}



.tabelwidth {
    width: 95%;
}

.thtab {
    border: 1px solid black !important;
    text-align: center !important;
    padding-top: 3vh !important;

}

.thTAb {
    border-top: 1px solid black !important;
}

.rowtabs {
  
    font-weight: 600;
    // border: 8px solid red !important;
    text-align: center !important;
    padding-top: 6vh !important;
}


.tableTAB,
.thTAb,
.tdtab {
    bordeR: 1px solid black;
    border-collapse: collapse;
    color: #000;
}

.thTAb,
.tdtab {
    padding: 23px !important;
    // width: 20%;
    // width: 10%;
    text-align: center !important;
    align-items: center !important;
    // border:2px solid red;
    font-size: 15px !important;
}

.normal {
    font-weight: normal !important;
}