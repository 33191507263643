#players {
    // border: 1px solid red;
    padding-left: 0px !important;
    padding-right: 0px !important;
    background-image: url("../../../assets/Images/SRFIbannerimage-02.jpg");
    height: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;


}



.PLAYERS-PART1 {
    // border: 1px solid red; 
    padding-left:  0px !important;
    padding-right: 0px !important;
    .PLAYERS_inner_wrapper {
        // border: 1px solid blue;
        background-image: url("../../../assets/Images/SRFIbannerimage-02.jpg");
        height: 100%;
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;

        padding-top: 57px;

        .navbar2-container-PLAYERS {
            border-radius: 5px;
            background: #f04a3e;
            height: 75px;
            width: 71%;
            padding-left: 10px;
            margin: 0 auto;
            // position: relative;
            // top: 35px;
            padding-right: 10px;

            @media screen and (min-width: 300px) and (max-width: 600px) {
                width: 100%;
            }

            @media screen and (min-width: 600px) and (max-width: 1300px) {
                width: 85%;
            }
        }

        .content-PLAYERS {
            width: 71%;
            height: 100%;
            background-color: #f5f4f4;
            color: #000;
            padding: 5px 15px !important;
            //    border:3px solid red ;
            margin: 0 auto !important;
            margin-top: 20px !important;

            @media screen and (min-width: 300px) and (max-width: 600px) {
                width: 100%;
            }

            @media screen and (min-width: 600px) and (max-width: 1300px) {
                width: 85%;
            }
        }
    }
}




























@mixin tabstyles {
    color: #000;
    background-color: #f5f4f4;
    padding: 5px 15px;
}

.TAB4 {
    .tabs {
        @include tabstyles;
    }
}

.TAB4 {
    .nav-pills>li>a {
        border-radius: 0;
    }
}

.imgapart {
    // border :1px solid rgb(8, 4, 4) !important;
    position: relative;
    top: -1.7vh;
    display: none;
}

.nav-pills>li.active>a {
    background-color: #f5f4f4 !important;
    color: #f04a3e !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    // border:2px solid black; 
    line-height: normal;
    border: none !important;

    border-radius: none;


}

.nav-pills>li>a:hover {
    background-color: #f5f4f4;
    color: #f04a3e !important;
}

.playerNavigation {
    color: #fff;
    border-radius: 5px;
    width: 71%;
    // width: 65%;
    margin-top: 20px;
    margin: 0 auto;
    margin-bottom: 3vh;
    padding-bottom: 2vh;
    //   border:2px solid red;
    background-color: #f5f4f4;
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;

    @media screen and (min-width: 300px) and (max-width: 600px) {
        //  border:12px solid rgb(243, 0, 0) !important;
        width: 100% !important;

    }

    .tabs {
        border-top-right-radius: 5px !important;
        border-top-left-radius: 5px !important;
        // border: 4px solid blue;
        background-color: #f5f4f4;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 0 !important;
    }

    .tab1 {
        // border: 4px solid blue;
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: start;
        margin-top: 20px;
        border-top-right-radius: 5px !important;
        border-top-left-radius: 5px !important;

    }

    .ActiveContent {
        border: 3px solid red;
    }

   


}
.para-partINPlay {
    // border:3px solid red ;  
    margin-top: -4vh;
    padding-top: 2vh;
}

.para-partINPlay .paragraph {
    padding-left: 5vh;
    width: fit-content;
    // border:3px solid green;
}
.contentPoints {
    //    border:5px solid red !important;
    //    padding-left: 6vh !important;
    list-style-type: disc;
    margin-top: 2vh !important;
}

// ----------------------heading--------------------------
.HeadingTAB {
    color: #f04a3e;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 24px;
    padding: 1vh;
    // border:2px solid red ;
    margin-left: -3px !important;
    line-height: 28.13px;

    @media screen and (min-width: 300px) and (max-width: 425px) {
        font-size: 20px;
      }

}

.paragraph {
    color: #000;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    line-height: 25px;
    // border:2px solid red;
    padding-right: 3vh;
    // padding: 2vh;
    // margin-left: 5vh;
    // padding-top: 3vh;
    padding-left: 6vh;
    margin-top: -2vh;
    text-align: start;

}

// -----------black--Heading---------------------------
.Head-Content {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    // border:2px solid red;
    text-align: start;
    margin-left: 1.5vh;
    margin-top: -4vh;
}

#navigations {
    background: #f04a3e;
    width: 100%;
    //   border: 2px solid black;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;


}

@media screen and (min-width: 300px) and (max-width: 600px) {
    .paragraph {
        word-spacing: none !important;
        line-height: none !important;
    }
}