.latest-result-main-wrapper {
  //   border: 1px solid green;
  background-image: url("../../assets//Images/SRFIbannerimage-02.jpg");
  height: 1150px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 1150px;
  width: 100%;

  @media screen and (min-width: 100px) and (max-width: 1350px) {
    // border: 2px solid red;
    background-image: url("../../assets//Images/SRFIbannerimage-02.jpg");
    height: 2000px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% 2000px;
    width: 100%;
  }

  .latest-result-inner {
    height: 100%;
    padding-top: 75px;
    width: 100%;
    // border: 1px solid red;

    
    .result-cont {
      height: 100%;
      // border: 1px solid green;
      padding: 10px;
      width: 100%;

      .result-news {
        width: 100%;
        height: 100%;
        // border: 1px solid brown;


        @media screen and (min-width: 100px) and (max-width: 1350px) {
          display: block;
          // border: 2px solid red;
        }

        .result-table {
          // margin: 0 auto;


          .latest-result-head {
            flex-direction: column;
            display: flex;
            margin-left: 180px;

            @media screen and (min-width: 100px) and (max-width: 1350px) {
              margin: 0 auto;
            }

            .latest-result-btn {
              border: none;
              outline: none;
              background-color: transparent;

              color: #000;
              // font-family: Roboto;
              font-size: 36px;
              font-style: normal;
              font-weight: 700;
              // line-height: normal;
              letter-spacing: 3.6px;
              text-align: left;
            }

            .btn-border {
              width: 132.161px;
            }
          }

          .result-table-main-wrapper {
            // display: flex;
            margin-top: 20px;
            margin-left: 180px;
            // border: 1px solid;
            height: 100%;

            @media screen and (min-width: 300px) and (max-width: 680px) {
              width: 100%;
              margin: 0 auto;

            }

            @media screen and (min-width: 100px) and (max-width: 1350px) {
              margin: 0 auto;
            }

            .venue-dropdown {
              //   position: relative;
              //   display: block;
              margin-top: 20px;

              .custom-dropdown {
                border-radius: 5px 5px 0px 0px;
                background: #f04a3e;
                width: 650px;
                height: 54px;
                padding: 13px;
                text-align: left;
                display: flex;
                align-items: center;
                cursor: pointer;
                border-left: 5px solid #3a8cd5;

                @media screen and (min-width: 300px) and (max-width: 800px) {
                  width: 100%;
                }

                .venue {
                  color: #fff;
                  font-size: 22px;
                  font-weight: 700;
                  cursor: pointer;
                }

                .dd-btn {
                  border: none;
                  outline: none;
                  background-color: transparent;
                  width: 32px;
                  height: 32px;
                  background-color: #fff;
                  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;
                  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
                  border-radius: 50%;
                  margin-left: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg {
                    color: #f04a3e;
                    width: 30px;
                    height: 30px;
                  }
                }
              }

              .venue-list {
                list-style: none;
                padding: 0;
                margin: 0;
                position: absolute;
                top: 100%;
                left: 0;
                background: #f04a3e;
                border-radius: 0 0 5px 5px;
                display: none;
                width: 100%;

                .venue-name {
                  color: #fff;
                  font-size: 22px;
                  font-weight: 700;
                  padding: 13px;
                  cursor: pointer;
                  //   border: 1px solid;
                  margin: 10px;
                  text-align: left;
                }
              }

              &:hover .custom-dropdown .venue-list {
                display: block;
              }
            }

            .table-data-wrapper {
              width: 650px;
              //   height: 871px;
              border-radius: 0px 0px 5px 5px;
              background: #e6e5e6;
              box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
              // border: 1px solid;
              height: 800px;

              @media screen and (min-width: 300px) and (max-width: 680px) {
                width: 100%;
              }

              .table-heading {
                display: flex;
                // border: 1px solid green;
                justify-content: space-around;
                height: 70px;
                // width: 100%;
                height: 100px;

                font-size: 22px;
                font-style: normal;
                font-weight: 500;

                .player-head-btn {
                  border: none;
                  outline: none;
                  background-color: transparent;

                  font-weight: 500;
                }

                ul {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  list-style: none;
                  width: 43%;
                  margin: 0px;
                  // gap: 48px;

                  li {
                  }
                }
              }

              .divider {
                width: 599px;
                height: 1px;
                opacity: 0.5;
                background: #000;
                margin: 0 auto;
              }

              .junior-data-container {
                // border: 1px solid red;

                .junior-head-dd {
                  // border: 1px solid green;
                  width: 100%;
                  display: flex;
                  align-items: center;
                  margin-top: 10px;
                  justify-content: start;
                  padding-left: 20px;
                  border-bottom: 1px solid;
                  border-top: 1px solid;
                  height: 52px;

                  .junior-div {
                    .junior-btn {
                      color: #f04a3e;
                      // font-family: Roboto;
                      font-size: 24px;
                      font-style: normal;
                      font-weight: 700;
                      // line-height: normal;
                      border: none;
                      outline: none;
                      background-color: transparent;
                    }
                  }

                  .junior-dropdown {
                    display: flex;
                    align-items: center;
                    .category {
                      color: #000;
                      font-size: 13px;
                      font-style: normal;
                      font-weight: 700;
                    }

                    .cat-dd-btn {
                      border: none;
                      outline: none;
                      background-color: transparent;
                      width: 27px;
                      height: 27px;
                      background-color: #f04a3e;
                      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;
                      filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
                      border-radius: 50%;
                      margin-left: 10px;
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      svg {
                        color: #fff;
                        width: 30px;
                        height: 30px;
                      }
                    }
                  }
                }

                .table-datas {
                  // width: 100%;
                  border-bottom: 1px solid;

                  table {
                    width: 93%;
                    height: 52px;

                    tbody {
                      tr {
                        height: 52px;

                        th {
                          text-align: left;
                          // border: 1px solid;
                          width: 45%;
                          padding-left: 30px;

                          .trophy {
                            height: 17px;
                            width: 17px;
                            margin-left: 5px;
                          }
                        }

                        td {
                          // border: 1px solid;
                          width: 20px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .all-results-btn {
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;

            border: none;
            outline: none;
            border-radius: 5px;
            background: #f04a3e;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset,
              4px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
            padding: 5px;
            margin-top: 150px;
            margin-bottom: 10px;
            // padding-top: ;
          }
        }

        .news-box {
          //   border: 1px solid blue;

          .latest-news-head {
            flex-direction: column;
            display: flex;
            // margin-left: 50px;

            .latest-news-btn {
              border: none;
              outline: none;
              background-color: transparent;

              color: #000;
              // font-family: Roboto;
              font-size: 36px;
              font-style: normal;
              font-weight: 700;
              // line-height: normal;
              letter-spacing: 3.6p;
              text-align: left;
            }
            .btn-border {
              width: 132.161px;
            }
          }

          .latest-news-main-wrapper {
            width: 550px;
            height: 850px;
            margin-top: 20px;
            border-radius: 0px 0px 5px 5px;
            background: #e6e5e6;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

            @media screen and (min-width: 300px) and (max-width: 600px) {
              width: 100%;
            }

            .latest-news-inner {
              border-radius: 5px 5px 0px 0px;
              background: #f04a3e;
              // width: 647px;
              height: 54px;
              padding: 13px;
              text-align: left;
              display: flex;
              align-items: center;
              cursor: pointer;
              border-left: 5px solid #3a8cd5;

              .news-head {
                color: #fff;
                font-size: 22px;
                font-weight: 700;
                cursor: pointer;
              }
            }

            .news-data-wrapper {
              width: 550px;
              overflow-y: auto;
              height: 90%;
              padding: 8px;
              @media screen and (min-width: 300px) and (max-width: 600px) {
                width: 100%;
              }

              .news-data-cont {
                .news-img {
                  width: 550px;
                  height: 300px;
              
                }

                .news-title {
                  color: #000;
                  // font-family: Roboto;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  text-align: left;
                  padding-left: 20px;
                  padding-top: 5px;
                }

                .news-date {
                  color: #000;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  text-align: right;
                  padding-right: 15px;
                }

                .news-divider {
                  width: 95%;
                  height: 2px;
                  opacity: 0.5;
                  background: #000;
                  margin: 10px;
                  margin-bottom: 10px;
                }
              }
            }
            ::-webkit-scrollbar {
              display: none !important;
            }
          }
        }
      }
    }
  }
}
