.calender-main-wrapper {
  .calender-inner {
    background-image: url("../../../assets/Images/SRFIbannerimage-02.jpg");
    height: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;

    padding-top: 57px;

    .navbar2-container {
      border-radius: 5px;
      background: #f04a3e;
      height: 75px;
      width: 70%;
      padding-left: 10px;
      margin: 0 auto;
      // position: relative;
      // top: 35px;
      padding-right: 10px;

      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 95%;
      }

      @media screen and (min-width: 600px) and (max-width: 1300px) {
        width: 83%;
      }

      // @media screen and (min-width: 1200px) and (max-width: 1400px) {
      //   width: 85%;
      // }
    }

    .shimmer {
      h4 {
        text-align: left;

        b {
          color: #f04a3e;
          // font-family: Roboto;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }

    .subnavbar-wrapper {
      // Define variables for common styles
      $bg-color: #f5f4f4;
      $text-color: #000;

      // border: 2px solid green;

      border-radius: 5px;
      // background: #f04a3e;
      // height: 75px;
      width: 71.5%;
      margin: 0 auto;
      margin-top: 20px;

      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 900px) {
        width: 88%;
      }

      @media screen and (min-width: 900px) and (max-width: 1300px) {
        width: 85%;
      }

      // Define a mixin for shared styles
      @mixin tab-styles {
        color: $text-color;
        background-color: $bg-color;
        padding: 5px 15px;
      }

      // Apply styles to #exTab1 .tab-content
      #exTab1 {
        .tab-content {
          @include tab-styles;
        }
      }

      // Apply styles to #exTab2 h3
      #exTab2 {
        h3 {
          @include tab-styles;
        }
      }

      // Remove border radius for the tab in #exTab1
      #exTab1 {
        .nav-pills > li > a {
          border-radius: 0;
        }
      }

      // Change border radius for the tab in #exTab3
      #exTab3 {
        .nav-pills > li > a {
          // border-radius: 4px 4px 0 0;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          height: 60px;
          width: 130px;
          display: block;
          padding-left: 15px;
          text-decoration: none;
          padding-top: 20px;
        }

        // Apply styles to #exTab3 .tab-content
        .tab-content {
          @include tab-styles;
        }
      }

      // custom tabs
      .nav-pills > li.active > a {
        background-color: #f5f4f4;
        color: #f04a3e !important;
        // border-left: 3px solid #000;
        color: #f04a3e;
        // font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        // line-height: normal;
        background-image: url("../../../assets/Images/btn-design.png");
        background-size: 128px 9%;
        background-repeat: no-repeat;
        background-position: bottom center;
        // background-position-y: 45px;
      }
      .nav-pills > li > a:hover {
        background-color: #f5f4f4;
        color: #f04a3e !important;
      }

      .navtabs-wrapper {
        .nav-pills {
          background: #f04a3e;
          display: flex;
          gap: 2px;
          justify-content: l;
          // height: 60px;
          align-items: center;

          .subnavbar {
            .subnav-list {
              &.active {
                .tab-image {
                  display: block;
                  margin-top: 5px;
                  width: 90px;
                  height: auto;
                  margin-left: auto;
                  margin-right: auto;
                }
              }
              .tab-image {
                display: block;
                margin-top: 5px;
                width: 90px;
                height: auto;
                margin-left: auto;
                margin-right: auto;
              }
              // color: #fff !important;
            }

            // ::after {
            //   content: "";
            //   position: absolute;
            //   right: 0;
            //   z-index: 100;
            //   top: 0;
            //   width: 1px;
            //   height: 50%;
            //   background: #555;
            // }
          }
        }

        .clearfix {
          .tab-pane {
            .navmargintop {
              width: 100%;
              height: 100%;
              // margin: 20px;

              .shimmer {
                padding: 15px;
                padding-left: 0px;
                h4 {
                  b {
                    color: #f04a3e;
                    // font-family: Roboto;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                  }
                }
              }

              .calender-main-container {
                // border: 1px solid red;
                width: 100%;
                height: 100%;

                .card-more-details-wrapper {
                  .back-btn {
                    svg {
                      height: 15px !important;
                      width: 15px !important;
                      margin: 4px;
                    }
                    padding: 8px;
                    color: #fff;
                    border-radius: 5px;
                    background: #f04a3e;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    margin-left: 15px;
                    margin-bottom: 50px;
                  }

                  .inner-card-fulldetails {
                    width: 65%;
                    margin: 0 auto;

                    &.active {
                      display: none;
                    }
                    @media screen and (min-width: 300px) and (max-width: 450px) {
                      width: 100%;
                      // border: 1px solid red;
                      display: block;
                    }
                    @media screen and (min-width: 450px) and (max-width: 650px) {
                      width: 100%;
                      // border: 1px solid;
                    }
                    .card2 {
                      // cursor: pointer;
                      // border: 1px solid red;
                      border: none;
                      border-radius: 5px;
                      background: #fff;
                      box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
                      margin-bottom: 50px;
                      height: 67%;

                      .card-body {
                        display: flex;
                        // flex-direction: column;
                        height: 100%;
                        align-items: center;

                        @media screen and (min-width: 300px) and (max-width: 550px) {
                          padding-left: 0px;

                          display: flex;
                          flex-direction: column-reverse;
                        }

                        .calender-details {
                          flex-grow: 1;
                          display: flex;
                          flex-direction: column;
                          gap: 25px;
                          padding-top: 15px;
                          padding-left: 10px;

                          .calender-date {
                            display: flex;
                            // align-items: center;
                            gap: 10px;

                            // @media screen and (min-width: 300px) and (max-width: 450px) {
                            //   color: #007bff;
                            // }

                            .date-img {
                              // border: 1px solid;
                            }

                            .tourdate {
                              color: #000;
                              font-family: Roboto;
                              font-size: 16px;
                              font-style: normal;
                              font-weight: 500;
                              line-height: normal;
                            }
                          }

                          .calender-tour {
                            display: flex;
                            // align-items: center;
                            gap: 10px;

                            .bat-img {
                              // border: 1px solid;
                              height: 25px;
                              width: 25px;
                              margin-top: 7px;
                            }

                            .tourname {
                              color: #000;
                              font-family: Roboto;
                              font-size: 18px;
                              font-style: normal;
                              font-weight: 500;
                              text-align: left;
                              line-break: anywhere;
                            }
                          }

                          .calender-venue {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            // padding-bottom: 10px;

                            .location-img {
                              // border: 1px solid;
                            }

                            .tourvenue {
                              color: #000;
                              font-family: Roboto;
                              font-size: 18px;
                              font-style: normal;
                              font-weight: 500;
                              text-align: left;

                              padding-top: 10px;
                            }
                          }
                        }

                        .img-cont {
                          display: flex;
                          flex-direction: column;
                          // border: 1px solid;
                          height: 100%;
                          margin-top: -60px;

                          .logo {
                            width: 100px;
                            height: 100px;
                            border-radius: 100px;
                            box-shadow: 0px 3.8806px 3.8806px 0px
                                rgba(0, 0, 0, 0.25),
                              0px 3.8806px 3.8806px 0px rgba(0, 0, 0, 0.25)
                                inset;

                            img {
                              width: 100px;
                              height: 100px;
                              border-radius: 50%;
                              padding: 5px;
                            }
                          }

                          .star {
                            width: 50px;
                            height: 50px;
                            margin: 0 auto;

                            // img {
                            //   width: 65px;
                            //   height: 65px;
                            // }

                            .starstatus {
                              // border: 1px solid #f04a3e;
                              color: #f04a3e;
                              display: block;
                              border-radius: 50%;
                              width: 100%;
                              height: 100%;
                              padding-left: 17px;
                              padding-top: 10px;
                              margin-top: 15px;

                              font-size: 20px;
                              font-weight: 600;
                            }
                          }
                        }
                      }
                    }

                    @media screen and (min-width: 300px) and (max-width: 450px) {
                      width: 100%;
                      margin-top: 25px;
                    }
                  }

                  .info-entries-match-video {
                    // border: 1px solid;
                    width: 100%;

                    .subnavbar {
                      display: flex;
                      gap: 20px;
                      background-color: #f0f0f0;
                      padding: 30px;

                      .tab {
                        margin-right: 10px;
                        position: relative; /* Set the position to relative */
                      }

                      button {
                        background-color: transparent;
                        border: none;
                        cursor: pointer;
                        outline: none;
                        font-size: 16px;
                        color: #000;

                        &.active {
                          font-weight: bold;
                          color: #000;
                          // font-family: Roboto;
                          font-size: 18px;
                          // font-style: normal;
                          font-weight: 600;
                          line-height: normal;

                          &::after {
                            content: "";
                            display: block;
                            width: 100%; /* Adjust the width as needed */
                            border-bottom: 3px solid #f04a3e; /* Adjust the thickness and color as needed */
                            position: absolute;
                            bottom: 0;
                            left: 0;
                          }
                        }
                      }

                      button {
                        background-color: transparent;
                        border: none;
                        cursor: pointer;
                        outline: none;
                        font-size: 16px;

                        &.active {
                          font-weight: bold;
                        }
                      }
                    }

                    .info-container {
                      border-radius: 5px;
                      background: #fff;
                      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                      width: 100%;
                      height: 100%;
                      padding-bottom: 15px;
                      padding-top: 1px;

                      .coming-soon {
                        margin: 20px;
                        color: black;
                        font-size: 20px;
                        font-weight: 500;
                        text-align: center;
                      }

                      .info-container-inner {
                        .card-wrapper {
                          display: flex;
                          gap: 10px;
                          padding: 20px;
                          justify-content: space-evenly;
                          width: 100%;

                          @media screen and (min-width: 300px) and (max-width: 700px) {
                            display: block;
                            margin: 0 auto;
                          }

                          .box-one {
                            width: 285px;
                            height: 170px;
                            border-radius: 5px;
                            background: #f04a3e;
                            box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
                            padding: 35px;
                            @media screen and (min-width: 300px) and (max-width: 700px) {
                              margin: 10px auto;
                              width: 90%;
                            }

                            .no {
                              color: #fff;
                              font-family: Roboto;
                              font-size: 48px;
                              font-style: normal;
                              font-weight: 600;
                              line-height: normal;
                              text-align: center;
                            }
                            .head {
                              color: #fff;
                              font-family: Roboto;
                              font-size: 24px;
                              font-style: normal;
                              font-weight: 700;
                              text-align: center;
                              line-height: normal;
                            }
                          }
                        }
                      }
                      .tabledata {
                        margin-left: 45px;
                        line-height: 2;

                        @media screen and (min-width: 300px) and (max-width: 450px) {
                          margin-left: 10px;
                        }

                        @media screen and (min-width: 450px) and (max-width: 575px) {
                          margin-left: 10px;
                        }

                        thead {
                          background-color: #f04a3e;
                        }

                        tbody {
                          tr {
                            .hed1 {
                              color: #000;
                              font-family: Roboto;
                              font-size: 18px;
                              font-style: normal;
                              font-weight: 500;
                              line-height: normal;
                              text-align: left;
                              padding: 8px;

                              @media screen and (min-width: 300px) and (max-width: 450px) {
                                font-size: 10px;
                              }
                              @media screen and (min-width: 450px) and (max-width: 575px) {
                                font-size: 12px;
                              }
                            }

                            .colon {
                              padding: 5px;
                            }
                            .det {
                              @media screen and (min-width: 300px) and (max-width: 450px) {
                                font-size: 10px;
                              }

                              @media screen and (min-width: 450px) and (max-width: 575px) {
                                font-size: 12px;
                              }
                            }
                          }
                        }
                      }
                    }

                    .entries-container {
                      .tourcatselect {
                        margin-top: 20px;
                        margin-left: 10px;

                        background: #fff;
                        border: none;
                        border-radius: 5px;
                        box-shadow: 0 4px;
                        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
                        color: #000 !important;
                        display: inline;
                        font-family: Roboto;
                        font-size: 19px;
                        font-weight: 400;
                        outline: none;
                        padding: 7px;

                       
                      }
                      // .bs-select {
                      //   width: 25% !important;
                      //   margin: 10px;
                      //   // border: 1px solid;
                      //   label {
                      //     width: 90%;
                      //   }
                      // }
                      thead {
                        background-color: #f04a3e;

                        tr {
                          th {
                            text-align: left !important;
                            color: #fff;
                            // font-family: Roboto;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                          }
                        }
                      }

                      tbody {
                        tr {
                          td {
                            color: #000;
                            // font-family: Roboto;
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-align: left;
                          }
                        }
                      }

                      .page-link {
                        @media screen and (min-width: 300px) and (max-width: 450px) {
                          padding: 10px;
                        }
                      }

                      .page-item.active .page-link {
                        z-index: 3;
                        color: black;
                        background-color: #007bff;
                        border-color: #007bff;
                        border-radius: 5px;
                        background: #fff;
                        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset,
                          2px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
                      }

                      a.page-link.page-link {
                        border: none;
                        color: black;
                      }

                      .pagination {
                        display: flex;
                        padding-left: 0;
                        list-style: none;
                        border-radius: 0.25rem;
                        float: right;

                        @media screen and (min-width: 300px) and (max-width: 450px) {
                          // border: 1px solid;
                          width: 100%;
                          position: relative;
                          left: -25px;
                        }
                      }

                      .page-item {
                        // Common styles for all pagination items
                        a.page-link {
                          border: none;
                          color: black;
                          cursor: pointer;
                        }
                      }
                      .page-item.active {
                        // Styles for active pagination item
                        a.page-link {
                          color: #000;
                          background-color: #007bff;
                          border-color: #007bff;
                          border-radius: 5px;
                          background: #fff;
                          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset,
                            2px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
                          cursor: default; // Change the cursor for active item
                        }
                      }

                      .page-item.disabled {
                        // Styles for disabled pagination item
                        a.page-link {
                          color: #888;
                          cursor: not-allowed; // Change the cursor for disabled item
                        }
                      }
                    }
                  }
                }

                .filter-wrapper {
                  padding-left: 25px;

                  @media screen and (min-width: 290px) and (max-width: 768px) {
                    padding-left: 0px;
                  }

                  .filter-dropdown {
                    display: flex;
                    gap: 10px;

                    @media screen and (min-width: 290px) and (max-width: 768px) {
                      display: block;
                      // border: 1px solid;
                    }

                    // .select {
                    //   li {
                    //     display: none;
                    //     cursor: pointer;
                    //     padding: 5px 10px;
                    //     border-top: 1px solid #7A7A7A;
                    //     min-width: 150px;

                    //     &:first-child {
                    //       display: block;
                    //       border-top: 0px;
                    //     }

                    //     &:hover {
                    //       background-color: #ddd;
                    //     }
                    //   }
                    // }

                    // .select {
                    //   width: 30%;
                    //   // height: 15%;
                    //   display: inline-block;
                    //   padding: 0;
                    //   position: relative;

                    //   border-radius: 5px;
                    //   background: #fff;
                    //   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

                    //   li:first-child {
                    //     &:hover {
                    //       background-color: transparent;
                    //     }
                    //   }

                    //   &.open {
                    //     li {
                    //       display: block;
                    //     }
                    //   }

                    //   // span:before {
                    //   //   position: absolute;
                    //   //   top: 5px;
                    //   //   right: 15px;
                    //   //   content: "\2193";
                    //   // }

                    //   // &.open {
                    //   //   span:before {
                    //   //     content: "\2191";
                    //   //   }
                    //   // }
                    // }

                    .tourcatogory {
                      border: none;
                      width: 25%;
                      border-radius: 5px;
                      background: #fff;
                      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

                      @media screen and (min-width: 290px) and (max-width: 768px) {
                        width: 50%;
                        // border: 1px solid;
                        // margin: 0 auto;
                        margin-bottom: 10px;
                        padding-left: 0px;
                      }

                      select {
                        border: none;
                        padding: 10px;
                        padding-left: 3px;
                        border-radius: 5px;
                        width: 100%;
                        height: 100%;
                        background: #fff;
                        font-family: "Roboto" !important;
                        color: #000;

                        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                        @media screen and (min-width: 290px) and (max-width: 768px) {
                          width: 100%;
                          padding: 8px;
                          // border: 1px solid;
                        }
                      }

                      option {
                        padding: 5px;
                        color: black;
                        font-style: normal;
                        font-family: "Roboto" !important;
                      }
                    }
                    .calyear {
                      border: none;
                      width: 10%;
                      border-radius: 5px;
                      background: #fff;
                      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

                      @media screen and (min-width: 290px) and (max-width: 768px) {
                        width: 50%;
                        // border: 1px solid;
                        // margin: 0 auto;
                        margin-bottom: 10px;
                        padding-left: 0px;
                      }

                      @media screen and (min-width: 768px) and (max-width: 1100px) {
                        // width: 15%;
                      }

                      select {
                        border: none;
                        padding-left: 3px;
                        border-radius: 5px;
                        width: 100%;
                        height: 100%;
                        background: #fff;
                        color: #000;
                        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

                        @media screen and (min-width: 290px) and (max-width: 768px) {
                          width: 100%;
                          padding: 8px;
                          // border: 1px solid;
                        }
                      }
                    }
                    .category {
                      border: none;
                      width: 10%;
                      border-radius: 5px;
                      background: #fff;
                      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                      @media screen and (min-width: 290px) and (max-width: 768px) {
                        width: 50%;
                        // border: 1px solid;
                        // margin: 0 auto;
                        margin-bottom: 10px;
                        padding-left: 0px;
                      }

                      @media screen and (min-width: 768px) and (max-width: 1100px) {
                        // width: 15%;
                      }

                      select {
                        border: none;
                        padding: 10px;
                        padding-left: 3px;
                        border-radius: 5px;
                        width: 100%;
                        color: #000;
                        height: 100%;
                        background: #fff;
                        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                      }
                    }
                    .star {
                      border: none;
                      width: 15%;
                      border-radius: 5px;
                      background: #fff;
                      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

                      @media screen and (min-width: 290px) and (max-width: 768px) {
                        width: 50%;
                        // border: 1px solid;
                        // margin: 0 auto;
                        margin-bottom: 10px;
                        padding-left: 0px;
                      }

                      @media screen and (min-width: 768px) and (max-width: 1100px) {
                        // width: 15%;
                      }

                      select {
                        border: none;
                        padding: 10px;
                        padding-left: 3px;
                        border-radius: 5px;
                        width: 100%;
                        height: 100%;
                        color: #000;
                        background: #fff;
                        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                      }
                    }

                    .search-container {
                      border: 1px solid #fff;
                      width: 279px;
                      // height: 35px;
                      border-radius: 5px;
                      background: #fff;
                      display: flex;

                      border-radius: 5px;
                      background: #fff;
                      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

                      svg {
                        color: #000;
                        width: 30px;
                        height: 30px;
                        padding: 3px;
                        margin: 1px;
                        margin-left: 5px;
                      }

                      .search-field {
                        border: none;
                        outline: none;
                        width: 179px;
                        // height: 30px;
                        border-radius: 5px;
                        background: #fff;
                        color: #000;
                      }
                      ::placeholder {
                        color: #000;
                        font-family: Roboto;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                      }
                    }
                  }
                }

                .calender-cards {
                  // border: 1px solid red;
                  padding: 10px;
                  margin-top: 50px;

                  @media screen and (min-width: 290px) and (max-width: 478px) {
                    padding: 0px;
                    position: relative;
                    right: 20px;
                  }

                  .container-fluid {
                    .monthandyear {
                      color: #f04a3e;
                      font-family: Roboto;
                      font-size: 20px;
                      font-style: normal;
                      font-weight: 600;
                      padding-bottom: 20px;
                      // line-height: normal;
                    }
                    .row {
                      // padding: 2rem 0rem;

                      .card-outer {
                        width: 100%;
                        //height: 100%;

                        .closed {
                          color: #fff;
                          padding: 5px;
                          border-radius: 5px 5px 0px 0px;
                          background: #f04a3e;
                          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                        }

                        .card {
                          cursor: pointer;
                          // border: 1px solid red;
                          border: none;
                          border-radius: 5px;
                          background: #fff;
                          box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
                          margin-bottom: 50px;
                          height: 67%;
                          @media screen and (min-width: 300px) and (max-width: 450px) {
                            width: 113%;
                            margin-top: 40px;
                            margin-bottom: 10px;
                            // border: 1px solid red;
                          }

                          .card-body {
                            display: flex;
                            // flex-direction: column;
                            height: 100%;
                            align-items: center;

                            @media screen and (min-width: 300px) and (max-width: 400px) {
                              flex-direction: column-reverse;
                              padding-bottom: 5px;
                            }

                            @media screen and (min-width: 400px) and (max-width: 500px) {
                              padding-left: 10px;
                            }

                            .calender-details {
                              flex-grow: 1;
                              padding: 20px;
                              display: flex;
                              flex-direction: column;
                              padding-right: 0px;

                              @media screen and (min-width: 300px) and (max-width: 450px) {
                                padding: 10px;
                              }
                              // @media screen and (min-width: 400px) and (max-width: 500px) {
                              //   padding: 10px;
                              //   border: 1px solid;
                              //   width: 220px;
                              // }

                              .calender-date {
                                display: flex;
                                // align-items: center;
                                gap: 10px;

                                @media screen and (min-width: 300px) and (max-width: 450px) {
                                  padding-top: 20px;
                                }

                                .date-img {
                                  // border: 1px solid;
                                }

                                .tourdate {
                                  color: #000;
                                  font-family: Roboto;
                                  font-size: 16px;
                                  font-style: normal;
                                  font-weight: 500;
                                  line-height: normal;
                                }
                              }

                              .calender-tour {
                                display: flex;
                                // align-items: center;
                                gap: 10px;

                                .bat-img {
                                  // border: 1px solid;
                                  height: 25px;
                                  width: 25px;
                                  margin-top: 7px;
                                }

                                .tourname {
                                  color: #000;
                                  font-family: Roboto;
                                  font-size: 18px;
                                  font-style: normal;
                                  font-weight: 500;
                                  text-align: left;
                                  line-break: anywhere;
                                }
                              }

                              .calender-venue {
                                display: flex;
                                align-items: center;
                                gap: 10px;
                                // padding-bottom: 10px;

                                @media screen and (min-width: 300px) and (max-width: 450px) {
                                  // padding-bottom: 15px;
                                }

                                .location-img {
                                  // border: 1px solid;
                                }

                                .tourvenue {
                                  color: #000;
                                  font-family: Roboto;
                                  font-size: 18px;
                                  font-style: normal;
                                  font-weight: 500;
                                  text-align: left;

                                  padding-top: 10px;
                                }
                              }
                            }

                            .img-cont {
                              display: flex;
                              flex-direction: column;

                              @media screen and (min-width: 300px) and (max-width: 400px) {
                                // border: 1px solid brown;
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                // margin-top: 97px;
                                // margin-bottom: -50px;
                              }

                              // my mobile redmi
                              //   @media only screen and (max-width: 1080px) and (max-height: 2340px) {
                              //  border: 1px solid red;
                              //   }

                              // justify-content: space-between;

                              .logo {
                                width: 100px;
                                height: 100px;
                                border-radius: 100px;
                                box-shadow: 0px 3.8806px 3.8806px 0px
                                    rgba(0, 0, 0, 0.25),
                                  0px 3.8806px 3.8806px 0px rgba(0, 0, 0, 0.25)
                                    inset;
                                position: relative;
                                bottom: 50px;


                                @media screen and (min-width: 300px) and (max-width: 350px) {
                                  position: relative;
                                  bottom: 0px;
                                  left: 45px;
                                }

                                @media screen and (min-width: 350px) and (max-width: 400px) {
                                  position: relative;
                                  bottom: 0px;
                                  left: 75px;

                                  // border: 1px solid yellow;
                                }

                                @media screen and (min-width: 400px) and (max-width: 500px) {
                                  width: 85px;
                                  height: 85px;
                                  //   position: relative;
                                  // bottom: 40px;
                                }

                                img {
                                  width: 100px;
                                  height: 100px;
                                  border-radius: 50%;
                                  padding: 5px;

                                  @media screen and (min-width: 400px) and (max-width: 500px) {
                                    width: 85px;
                                    height: 85px;
                                  }
                                }
                              }

                              .star {
                                position: relative;
                                width: 65px;
                                height: 65px;
                                bottom: 30px;
                                margin: 0 auto;
                                @media screen and (min-width: 300px) and (max-width: 350px) {
                                  position: relative;
                                  bottom: -70px;
                                  left: 35px;

                                  width: 45px;
                                  height: 45px;
                                }
                                @media screen and (min-width: 350px) and (max-width: 400px) {
                                  position: relative;
                                  bottom: -70px;
                                  left: 50px;

                                  width: 55px;
                                  height: 55px;
                                }

 

                                img {
                                  width: 65px;
                                  height: 65px;
                                  padding: 5px;

                                  @media screen and (min-width: 300px) and (max-width: 350px) {
                                     
                                    width: 45px;
                                    height: 45px;
                                  }
                                  @media screen and (min-width: 350px) and (max-width: 400px) {
                                    width: 55px;
                                    height: 55px;
                                  }
                                }
                              }
                            }
                          }
                          .register-wrap {
                            // border: 1px solid;
                            width: 100%;
                            padding-bottom: 20px;
                            text-align: center;

                            .register-date {
                              color: #000;
                              // font-family: Roboto;
                              font-size: 16px;
                              font-style: normal;
                              font-weight: 500;
                              line-height: normal;
                              padding-left: 10px;
                            }
                          }
                        }
                      }
                    }

                    .cal-no-data {
                      // border: 1px solid;
                      color: #000;
                      display: flex;
                      justify-content: center;
                    }
                  }

                  /* Media Query for smaller screens */
                  @media (max-width: 768px) {
                    .card-outer {
                      .card {
                        width: 100%;

                        // border: 1px solid !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  #scrollToTopButton {
    // display: none;
    position: fixed;
    bottom: 20px;
    right: 50px;
    // background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 99999;
    outline: none;
    @media screen and (min-width: 300px) and (max-width: 550px) {
      position: fixed;
      bottom: 20px;
      right: 10px;
    }

    .scrollup {
      height: 50px;
      width: 50px;
      border-radius: 50%;
    }
  }
}

tbody {
  // color: red !important;
  text-transform: uppercase;
}

.worldevent {
  text-align: left;
}
