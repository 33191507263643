.election-main-wrapper {
  // margin: 20px;

  .indiasquash_inner_wrapper {
    // border: 1px solid blue;
    background-image: url("../../../assets/Images/SRFIbannerimage-02.jpg");
    height: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;

    padding-top: 57px;


    .navbar2-container {
      border-radius: 5px;
      background: #f04a3e;
      height: 75px;
      width: 71%;
      padding-left: 10px;
      margin: 0 auto;
      // position: relative;
      // top: 35px;
      padding-right: 10px;

      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 1300px) {
        width: 85%;
      }
    }

    .election-inner {
      width: 71%;
      height: 100%;
      background-color: #f5f4f4;
      color: #000;
      padding: 5px 15px;
      margin: 0 auto;
      margin-top: 20px;
      @media screen and (min-width: 300px) and (max-width: 600px) {
        width: 100%;
      }

      @media screen and (min-width: 600px) and (max-width: 1300px) {
        width: 85%;
      }

      .shimmer {
        margin-top: 20px;
        text-align: left;
        h4 {
          text-align: left;

          b {
            color: #f04a3e;
            // font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }

      .election-btns-wrapper {
        .ruleslist {
          list-style: none;
          margin-left: 0%;

          .pt-2 {
            border: none;
            :hover {
              background-color: #f04a3e;
              color: #fff !important;
              border: none;
            }
          }

          .electrol-btn {
            padding: 1% 3%;
            width: 80%;
            color: white;
            border: none;
            outline: none;
            text-align: left;
            border-radius: 5px;
            // border: 1px solid rgba(0, 0, 0, 0.5);
            background: #fff;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            color: #000;
            // font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            // line-height: normal;

            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
