.guideline-main-wrapper {

  .indiasquash_inner_wrapper {
    // border: 1px solid blue;
    background-image: url("../../../assets/Images/SRFIbannerimage-02.jpg");
    height: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;

    padding-top: 57px;

    
  .navbar2-container {
    border-radius: 5px;
    background: #f04a3e;
    height: 75px;
    width: 71%;
    padding-left: 10px;
    margin: 0 auto;
    // position: relative;
    // top: 35px;
    padding-right: 10px;

    @media screen and (min-width: 300px) and (max-width: 600px) {
      width: 100%;
    }

    @media screen and (min-width: 600px) and (max-width: 1300px) {
      width: 85%;
    }
  }
  .guideline-inner {
    width: 71%;
    height: 100%;
    background-color: #f5f4f4;
    color: #000;
    padding: 5px 15px;
    margin: 0 auto;
    margin-top: 20px;
    
    @media screen and (min-width: 300px) and (max-width: 600px) {
      width: 100%;
    }

    @media screen and (min-width: 600px) and (max-width: 1300px) {
      width: 85%;
    }
    .shimmer {
      padding: 15px;
      padding-left: 0px;

      h4 {
        text-align: left;

        b {
          color: #f04a3e;
          // font-family: Roboto;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }

    .guidelines-container1 {
      padding-left: 20px;
      // border: 1px solid;
      .main-text {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        text-align: left;
        // line-height: 25px;
      }
    }

    .guidelines-container2 {
      padding-left: 20px;

      // border: 1px solid red;
      
      .keyrules {
        color: #000;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        text-align: left;
      }

      .FontSzInallMenus {
        text-align: left;
        list-style: disc;

        // ::before {
        //   content: "\2022";
        //   color: #ff0000;
        //   font-weight: bold;
        //   display: inline-block;
        //   width: 1em;
        //   margin-left: -1em;  
        // }
        .contantul {
         

        }

      }
      .guide-btn {
        padding: 1% 3%;
        margin: 2%;
        width: 80%;
        border: none;
        outline: none;
        text-align: left;
        border-radius: 5px;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: #000;
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        background-color: #f04a3e;

        display: flex;

      }
      .guide-btn:hover {
        background-color: #f04a3e;
        color: #fff;
        border: none;
    }


    }
  }
}
}